/* eslint-disable react/jsx-filename-extension */
import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Trans, useTranslation } from "react-i18next";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigateTo = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigateTo("/login");
  };
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Button
          size="small"
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          startIcon={
            <Avatar
              sx={{ width: 32, height: 32 }}
              src={window.localStorage.getItem("avatar")}
            />
          }
          endIcon={<ArrowDropDownIcon sx={{ color: "black" }} />}
          sx={{
            color: "#e87848",
            backgroundColor: "white",
            borderRadius: "20px",
            px: 3,
            "&:hover": {
              backgroundColor: "#2A4556",
              color: "white",
              fontWeight: "medium",
            },
          }}
        >
          {window.localStorage.getItem("user")}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            mt: 2.5,
            px: 3,
            py: 2,
            minWidth: "200px",
            boxShadow:
              "rgb(95 116 141 / 3%) 0px 5px 10px -3px, rgb(95 116 141 / 4%) 0px 8px 18px 1px, rgb(95 116 141 / 8%) 0px 3px 33px 2px",
            borderRadius: "5px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} to="/profile">
          <ListItem alignItems="flex-start" sx={{ padding: 0, mb: 1 }}>
            <ListItemAvatar>
              <Avatar
                alt="John Doe"
                src={window.localStorage.getItem("avatar")}
              />
            </ListItemAvatar>
            <ListItemText
              primary={window.localStorage.getItem("user")}
              sx={{ color: "rgb(18, 31, 67)" }}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: "inline",
                      color: "rgb(140, 163, 186)",
                      fontWeight: 500,
                    }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {window.localStorage.getItem("email")}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </MenuItem>
        <Divider />
        <MenuItem
          component={Link}
          to="/profile"
          sx={{
            padding: "5px 1rem",
            lineHeight: 1.6,
            fontWeight: 500,
            fontSize: "15px",
            color: "rgb(18, 31, 67)",
          }}
        >
          {t("profile")}
        </MenuItem>
        <MenuItem
          component={Link}
          to="/favorite"
          sx={{
            padding: "5px 1rem",
            lineHeight: 1.6,
            fontWeight: 500,
            fontSize: "15px",
            color: "rgb(18, 31, 67)",
          }}
        >
          {t("favorite")}
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          sx={{
            padding: "5px 1rem",
            lineHeight: 1.6,
            fontWeight: 500,
            fontSize: "15px",
            color: "rgb(18, 31, 67)",
          }}
        >
          {t("signout")}
        </MenuItem>
      </Menu>
    </>
  );
}
