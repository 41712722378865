import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { AboutUs1, AboutUs2, AboutUs3 } from "../../assets";

const Privacy = () => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        my: 2,
      }}
    >
      <Box sx={{ alignItems: "center", width: "100%", maxWidth: 1000 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Amaklo Privacy Policy
        </Typography>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Effective date: March 30, 2023
        </Typography>
        <br />
        <Typography variant="body1" gutterBottom>
          This Privacy Policy describes our policies on the collection, use, and
          disclosure of information about you in connection with your use of our
          services, including those offered through our websites, communications
          (e.g., emails, phone calls, and texts), and mobile applications
          (collectively, the “Service”). The terms “we”, “us”, and “Amaklo”
        </Typography>
        <Typography variant="body1" gutterBottom>
          When you use the Service, you consent to our collection, use, and
          disclosure of information about you as described in this Privacy
          Policy.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, the terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions, accessible from https://amaklo.com.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Information We Collect and How We Use It
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may collect, transmit, and store information about you in
          connection with your use of the Service, including any information you
          send to or through the Service. We use that information to provide the
          Service’s functionality, fulfill your requests, improve the Service’s
          quality, engage in research and analysis relating to the Service,
          personalize your experience, track usage of the Service, provide
          feedback to third party businesses that are listed on the Service,
          display relevant advertising, market the Service, provide customer
          support, message you, back up our systems, allow for disaster
          recovery, enhance the security of the Service, and comply with legal
          obligations. Even when we do not retain such information, it still
          must be transmitted to our servers initially and stored long enough to
          process.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>A. Account Information:</b> When you create a Amaklo account, we
          store and use the information you provide during that process, such as
          the first and last name you enter, email address, physical address,
          and any other information you may provide during the account creation
          process, such as a gender, phone number and more.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>B. Public Content:</b> Your contributions to the Service are
          intended for public consumption and are therefore viewable by the
          public, including your photos, ratings, reviews, tips, lists,
          bookmarks and collections, compliments, talk posts, and edits to
          business page information.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b> C. Activity:</b> We store information about your use of the
          Service, such as your search activity, the pages you view, the date
          and time of your visits, businesses you call using our mobile
          applications, and transactions you make through the Service. We also
          store information that your computer or mobile device may provide to
          us in connection with your use of the Service, such as activity
          counter.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>D. Different Devices: </b> You may access the Service through
          different devices (e.g., your mobile phone, personal computer, or
          other internet connected device) and different platforms (e.g.,
          through the Amaklo website or Amaklo mobile apps). The information
          that we collect and store through those different uses may be
          cross-referenced and combined, and your contributions through one
          Amaklo platform will typically be similarly visible and accessible
          through all other Amaklo platforms.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>E. Sensitive Personal Information: </b> While using the Service,
          you may choose to allow Amaklo to collect and store sensitive personal
          information about you.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <b>F. Tracking Cookies Data: </b>We use cookies and similar tracking
          technologies to track the activity on our Service and we hold certain
          information. Cookies are files with a small amount of data which may
          include an anonymous unique identifier. Cookies are sent to your
          browser from a website and stored on your device. Other tracking
          technologies are also used such as beacons, tags, and scripts to
          collect and track information and to improve and analyze our Service.
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Security
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use various safeguards to protect the personal information
          submitted to us, both during transmission and after we receive it.
          However, no method of transmission over the Internet or via mobile
          device, or method of electronic storage, is 100% secure.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Data Deletion
        </Typography>
        <Typography variant="body1" gutterBottom>
          It is your right to completely delete any data we have collected on
          you. Please send an email to contact@amaklo.com to request deletion of
          your data or you can delete your data from your account profile panel.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Security of Data
        </Typography>
        <Typography variant="body1" gutterBottom>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. We will
          let you know via email and/or a prominent notice on our Service, prior
          to the change becoming effective and update the “effective date” at
          the top of this Privacy Policy.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Contact Us
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          If you have any questions about these Terms, please contact us.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          By visiting this page on our website: https://amaklo.com/contact-us/
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          Email
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          info@amaklo.com sales@amaklo.com contact@amaklo.com
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          Phone
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          Number + (251) 908065891 + (251) 908064932
        </Typography>
        <br />
        <br />
        <br />
      </Box>
    </Paper>
  );
};

export default Privacy;
