import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { AboutUs1, AboutUs2, AboutUs3 } from "../../assets";

const Terms = () => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        my: 2,
      }}
    >
      <Box sx={{ alignItems: "center", width: "100%", maxWidth: 1000 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Terms Of Service
        </Typography>
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Last Updated on: March 30, 2023
        </Typography>
        <br />
        <Typography variant="body1" gutterBottom>
          Please read these Terms and Conditions (“Terms”, “Terms and
          Conditions”) are effective immediately for users accessing or using
          the Service without an Account or those registering Accounts on
          carefully https://amaklo.com website (the “Service” the “Business”)
          operated by Amaklo A “user” is someone who accesses or in any way uses
          the Service. (“us”,” “we”, or “our”) (“You” and “your”) refer to you,
          as a user of the Service.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </Typography>
        <Typography variant="body1" gutterBottom>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms, then you may not
          access the Service.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Accounts
        </Typography>
        <Typography variant="body1" gutterBottom>
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You are responsible for safeguarding the password that you use to
          access the Service and for any activities or actions under your
          password, whether your password is with our Service or a third-party
          service.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Links To Other Web Sites
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by Amaklo.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Amaklo has no control over, and assumes no responsibility for, the
          content, privacy policies, or practices of any third-party web sites
          or services. You further acknowledge and agree that Amaklo shall not
          be responsible or liable, directly, or indirectly, for any damage or
          loss caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods, or services available on or
          through any such web sites or services.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Termination
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms.
        </Typography>
        <Typography variant="body1" gutterBottom>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You may terminate the Terms at any time by closing your Account,
          discontinuing any access to or use of the Service, and providing
          Amaklo with a notice of termination.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may close your Account, suspend your ability to use certain
          portions of the Service, terminate any license or permission granted
          to you hereunder, and/or ban you altogether from the Service for any
          or no reason, and without notice or liability of any kind. Any such
          action could prevent you from accessing your Account, the Service,
          Your Content, Service Content, or any other related information.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Upon termination, your right to use the Service will immediately
          cease. If you wish to terminate your account, you may simply
          discontinue using the Service.
        </Typography>
        <Typography variant="body1" gutterBottom>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity, and limitations
          of liability.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Governing Law
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms shall be governed and construed in accordance with the
          laws of Ethiopia, without regard to its conflict of law provisions.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </Typography>
        <br />
        <Typography
          variant="h5"
          gutterBottom
          sx={{ color: "primary.main", alignItems: "center" }}
        >
          Contact Us
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          If you have any questions about these Terms, please contact us.
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          By visiting this page on our website: https://amaklo.com/contact-us/
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          Email
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          info@amaklo.com sales@amaklo.com contact@amaklo.com
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          Phone
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          Number + (251) 908065891 + (251) 908064932
        </Typography>
        <br />
        <br />
        <br />
      </Box>
    </Paper>
  );
};

export default Terms;
