import React from "react";
import { Grid, Box, Typography, Link, IconButton, Button } from "@mui/material";
import Power from "../../assets/images/madeby.png"
import {
  Email,
  Facebook,
  Instagram,
  Language,
  LinkedIn,
  LocationOn,
  LocationOnRounded,
  Phone,
  Telegram,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import Amaklo from "../../assets/images/amaklo.jpg";
import playStore from "../../assets/images/play-store.svg";
import appStore from "../../assets/images/app-store.svg";
import Tiktok from "../../assets/images/tiktok";
import { useNavigate } from "react-router-dom";
import Snapchat from "../../assets/images/snapchat";
import { Logo, Logo2 } from "../../assets";
import { useCount } from "../../hooks/api";
import { Trans, useTranslation } from "react-i18next";

function NavLink({ link, icon, onCount }) {
  const { t, i18n } = useTranslation();
  return (
    <Link
      sx={{
        color: "primary.main",
        fontSize: "15px",
        marginBottom: "10px",

        "&:hover": {
          color: "red",
          borderBottom: `6px solid green}`,
        },
      }}
      href={link}
      target="_blank"
      underline="hover"
      onClick={onCount}
    >
      <IconButton
        sx={{
          color: "primary.main",
          fontSize: "26px",
          marginBottom: "10px",

          "&:hover": {
            color: "red",
            borderBottom: `6px solid green}`,
          },
        }}
      >
        {icon}
      </IconButton>
    </Link>
  );
}

function Description({ title, description }) {
  const { t, i18n } = useTranslation();
  return (
    <Grid item lg={5} justifyContent={"flex-start"}>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 700,
          textAlign: "left",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: "13px",
          textAlign: "left",
          maxWidth: "17.5625rem",
          mb: 1,
        }}
      >
        {description}
      </Typography>
    </Grid>
  );
}

const BottomNav = () => {
  const navigate = useNavigate();
  const { mutate: count, isSuccess, isLoading, isError, error } = useCount();
  const { t, i18n } = useTranslation();
  return (
    <Grid
      container
      bgcolor={"#E8E8E8"}
      justifyContent="center"
      sx={{
        p: 3,
      }}
    >
      <Grid item xs={12} sm={10} md={6} lg={7}>
        <Grid
          container
          gap={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            lg={10}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: {
                lg: "row",
                md: "column",
                sm: "column",
                xs: "column",
              },
            }}
          >
            <Box
              component={"img"}
              sx={{
                width: "130px",
                maxHeight: "130px",
                alignSelf: "center",
                justifySelf: "center",
                my: "auto",
                backgroundRepeat: "no-repeat",
                // bgcolor: "white",
              }}
              src={Logo2}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: {
                  md: "center",
                  lg: "unset",
                  sm: "unset",
                  xs: "unset",
                },
                mx: 2,
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  textAlign: {
                    lg: "start",
                    md: "start",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                {t("amakloinfo")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  maxWidth: "30.5625rem",
                  textAlign: {
                    lg: "start",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  },
                }}
              >
                {t("amakloinfodetail")}
              </Typography>
            </Box>
          </Grid>
          <Description
            title={t("businessesfooter")}
            description={t("businessesfooterdetail")}
          />
          <Description
            title={t("interactivefooter")}
            description={t("interactivefooterdetail")}
          />
          <Description
            title={t("blogsfooter")}
            description={t("blogsfooterdetail")}
          />
          <Description
            title={t("bidfooter")}
            description={t("bidfooterdetail")}
          />
          <Description
            title={t("customersfooter")}
            description={t("customersfooterdetail")}
          />
          <Description
            title={t("categoriesfooter")}
            description={t("categoriesfooterdetail")}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={6}
        lg={2}
        sx={{
          display: "flex",
          justifyContent: {
            lg: "center",
            md: "center",
            sm: "center",
            xs: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 900,
              marginBottom: "20px",
              mr: 2,
            }}
            variant="h6"
          >
            {t("quicklinks")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              variant="Button"
              onClick={() => navigate("/")}
            >
              {t("businesslink")}
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              variant="Button"
              onClick={() => navigate("/blog")}
            >
              {t("blogslink")}
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              variant="Button"
              onClick={() => navigate("/about-us")}
            >
              {t("aboutuslink")}
            </Typography>

            {/* <Typography
              sx={{
                fontSize: "13px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              variant="Button"
              onClick={() => navigate("/faq")}
            >
              FAQ
            </Typography> */}
            <Typography
              sx={{
                fontSize: "13px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              variant="Button"
              onClick={() =>
                navigate("/signup", {
                  state: {
                    isBusiness: false,
                  },
                })
              }
            >
              {t("customerlink")}
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              variant="Button"
              onClick={() =>
                navigate("/signup", {
                  state: {
                    isBusiness: true,
                  },
                })
              }
            >
              {t("businessrlink")}
            </Typography>
            <Typography
              sx={{
                fontSize: "13px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              variant="Button"
              onClick={() => navigate("/pricing")}
            >
              {t("pricelink")}
            </Typography>
            {/* <Typography
              sx={{
                fontSize: "13px",
                marginBottom: "10px",
                cursor: "pointer",
              }}
              variant="Button"
              onClick={() => navigate("/")}
            >
              BUSINESS PAYMENT REF. SUBMIT
            </Typography> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              my: 2,

              flexDirection: "column",
            }}
          >
            <Link href="https://play.google.com/store/apps/details?id=com.amaklo">
              <Box
                component={"img"}
                sx={{
                  my: 1,
                  maxHeight: "99px",
                  width: "150px",
                  cursor: "pointer",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                src={playStore}
              />
            </Link>
            <Link href="https://apps.apple.com/us/genre/ios/id36">
              <Box
                component={"img"}
                sx={{
                  my: 1,
                  maxHeight: "99px",
                  width: "150px",
                  cursor: "pointer",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
                src={appStore}
              />
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={10}
        md={6}
        lg={2.5}
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: {
            lg: "end",
            md: "center",
            sm: "center",
            xs: "center",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 900,
            marginBottom: "20px",
            // textAlign: "end",
            mr: 2,
          }}
          variant="h6"
        >
          {t("contactfooter")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            // alignItems: "flex-end",
            height: "100%",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2,
                mr: 2,
              }}
            >
              <Phone />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // textAlign: "end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    ml: 2,
                  }}
                >
                  +(251) 908064932
                  <Typography> +(251) 908065891 </Typography>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2,
                mr: 2,
              }}
            >
              <Language />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // textAlign: "end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    ml: 2,
                  }}
                >
                  www.amaklo.com
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2,
                mr: 2,
              }}
            >
              <Email />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // textAlign: "end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    ml: 2,
                  }}
                >
                  info@amaklo.com
                  <Typography>sales@amaklo.com </Typography>
                  <Typography>contact@amaklo.com</Typography>
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2,
                mr: 2,
              }}
            >
              <LocationOn />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // textAlign: "end",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    ml: 2,
                  }}
                >
                  {t("addis")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Button
            sx={{
              bgcolor: "gray",
              color: "white",
              px: 2,
              py: 1,
              // my: 6,

              borderRadius: "4px",

              "&:hover": {
                bgcolor: "primary.main",
                color: "white",
              },
            }}
            onClick={() => navigate("/contact-us")}
          >
            {t("contactusfooter")}
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          my: 3,
          flexDirection: {
            lg: "row",
            md: "row",
            sm: "column-reverse",
            xs: "column-reverse",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "white",
            flexGrow: 0.5,
            ml: 9,
            py: 2,
          }}
        >
          <Typography
            variant={Button}
            sx={{ mx: 2, color: "primary.main", cursor: "pointer" }}
            onClick={() => navigate("/terms")}
          >
            {t("terms")}
          </Typography>
          <Typography
            variant={Button}
            sx={{ mx: 2, color: "primary.main", cursor: "pointer" }}
            onClick={() => navigate("/privacy")}
          >
            {t("privacy")}
          </Typography>
          <Typography
            variant={Button}
            sx={{ mx: 2, color: "primary.main", cursor: "pointer" }}
          >
            Powered by
 
          </Typography>
          <img src={Power} alt='power' width='80px' height='25px' style={{marginRight:'4px', marginTop:'0px'}}/>
        </Box>
        <Box
          sx={{
            mr: 1,
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <NavLink
            link={"https://www.instagram.com/shewamaklo/"}
            icon={<Instagram />}
            onCount={() => {
              count({
                _clicked: "social",
                _clickedtype: "ig",
                _ref: "instagram",
              });
            }}
          />

          <NavLink
            link={"https://t.me/amaklo"}
            icon={<Telegram />}
            onCount={() => {
              count({
                _clicked: "social",
                _clickedtype: "tg",
                _ref: "telegram",
              });
            }}
          />
          <NavLink
            link={"https://www.linkedin.com/in/shewa-amaklo-3a3221245"}
            icon={<LinkedIn />}
            onCount={() => {
              count({
                _clicked: "social",
                _clickedtype: "ln",
                _ref: "linkedin",
              });
            }}
          />
          <NavLink
            link={"https://twitter.com/Shewamaklo"}
            icon={<Twitter />}
            onCount={() => {
              count({
                _clicked: "social",
                _clickedtype: "tty",
                _ref: "twitter",
              });
            }}
          />
          <NavLink
            link={"https://youtube.com/channel/UCOuwjoP9_vLznIzykJBERPA"}
            icon={<YouTube />}
            onCount={() => {
              count({
                _clicked: "social",
                _clickedtype: "yt",
                _ref: "youtube",
              });
            }}
          />
          <NavLink
            link={" https://www.facebook.com/shewaamaklo"}
            icon={<Facebook />}
            onCount={() => {
              count({
                _clicked: "social",
                _clickedtype: "fb",
                _ref: "facebook",
              });
            }}
          />

          <NavLink
            link={"https://www.tiktok.com/@shewamaklo"}
            icon={<Tiktok />}
            onCount={() => {
              count({
                _clicked: "social",
                _clickedtype: "ttk",
                _ref: "tiktok",
              });
            }}
          />
          <NavLink
            link={
              "https://www.snapchat.com/add/shewamaklo?share_id=ar8XZCz4yk8&locale=en-US"
            }
            icon={<Snapchat />}
            onCount={() => {
              count({
                _clicked: "social",
                _clickedtype: "snp",
                _ref: "snapchat",
              });
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default BottomNav;
