import React from "react";
import "./index.css";
import { Box, CssBaseline } from "@mui/material";
import PageRoutes from "./app/routes";
import { SnackbarProvider } from "notistack";
import Axios from "axios";

function App() {
  Axios.defaults.headers.common["source"] = `web`;

  return (
    <SnackbarProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "open-sans",
          minHeight: "100vh",
          background:
            " linear-gradient(90deg, rgba(4,91,118,0.10547969187675066) 0%, rgba(17,82,120,0.10828081232492992) 35%, rgba(4,91,118,0.10547969187675066) 100%)",
        }}
      >
        <CssBaseline />
        <PageRoutes />
      </Box>
    </SnackbarProvider>
  );
}

export default App;
