import { Clear, Image } from "@mui/icons-material";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { A1, A2, A3, A5, A6, A7, A8, A9 } from "../../../assets";

import { A11y, Navigation, Pagination, Scrollbar } from "swiper";

import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Trans, useTranslation } from "react-i18next";
const useStyles = makeStyles(() => ({
  root: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  advert: {
    backgroundColor: "inherit",
    // margin: "-16px",
    marginBottom: 0,
    // padding: "20px",
    color: "white",
    minHeight: "250px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  search: {
    position: "relative",
    backgroundColor: "white",
    padding: "40px 0px",
    // bottom: "60px",
    bottom: "20px",
    width: "80%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: "10px !important",
  },
  container: {
    display: "flex !important",
    justifyContent: "center",
  },
  textInput: {
    "& .MuiFilledInput-input": {
      paddingTop: "0px important",
      "&:before": {
        borderBottom: "none !important",
      },
    },
  },
}));

function Advert({ text, searchText, title = "" }) {
  const classes = useStyles();
  const handleChange = (event) => {
    searchText(event.target.value);
  };
  const image = [A1, A2, A3, A5, A6];
  const disc = [
    "Connects All People",
    " Your Success Is",
    "Digital Advertising",
    "Explore",
    "The Place Where Your Business/Service",
  ];
  const titles = [
    "with Great Local And International Businesses",
    "Our Success",
    "That Works as Hard As You Do",
    "the World & Domestic Business",
    "Get the Right Digital Exposure",
  ];
  var content = Math.floor(Math.random() * disc.length);

  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.advert}>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          effect="flip"
          loop={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          pagination={false}
          // fill
          // breakpoints={{
          //   "@0.00": {
          //     slidesPerView: 1,
          //     spaceBetween: 10,
          //   },
          //   "@0.75": {
          //     slidesPerView: 2,
          //     spaceBetween: 20,
          //   },
          //   "@1.00": {
          //     slidesPerView: 3,
          //     spaceBetween: 40,
          //   },
          // }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {image.map((img, index) => {
            return (
              <SwiperSlide key={index}>
                <CardCover
                  sx={{
                    background:
                      "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0.4) 400px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)",
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    height: "500px",
                    backgroundPosition: "center",
                    objectFit: "fill",
                    // backgroundRepeat: "inherit",
                    backgroundSize: "cover",
                    backgroundImage: `url(${img})`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardContent>
                    {/* (content = Math.floor(Math.random() * disc.length)) */}
                    <Typography variant="h3" color="white" fontWeight={600}>
                      {disc[index]}
                    </Typography>
                    <Typography variant="h4" color="white">
                      {titles[index]}
                    </Typography>
                  </CardContent>
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
      <Container maxWidth="md" className={classes.container}>
        <Paper
          elevation={4}
          className={classes.search}
          sx={{
            mt: 7,
          }}
        >
          <FormControl sx={{ width: "inherit" }}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
              columnGap={1}
            >
              <Grid item xs={8}>
                <FormLabel
                  sx={{
                    fontWeight: "bolder",
                    fontSize: "large",
                    color: "black",
                    mb: 1,
                  }}
                >
                  {/* {title.toUpperCase()} */}
                </FormLabel>
                <TextField
                  fullWidth
                  id="filled-basic"
                  value={text}
                  variant="outlined"
                  label={`${title}`}
                  // placeholder={`Search By ${title}`}
                  sx={{
                    borderRadius: "15px",
                    textTransform: "capitalize",
                  }}
                  className={classes.textInput}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        onClick={() => searchText("")}
                      >
                        <Clear fontSize="small" />
                      </IconButton>
                    ),
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={3} mx={2} mt={3} alignSelf="center">
                <Button
                  variant="contained"
                  color="primary"
                  alignItems="center"
                  sx={{ md: 3, mx: 2, px: 5, borderRadius: "1px" }}
                >
                  {t("search")}
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Paper>
      </Container>
    </Box>
  );
}

export default Advert;
