import { MenuOpen, MenuOpenOutlined, MenuOutlined } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Divider,
  IconButton,
  Link,
  ListItemButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import React from "react";
import { Link as RouterLink, NavLink, useNavigate } from "react-router-dom";
import { Logo } from "../../assets";
import AccountMenu from "./AccountMenu";
import LocalMenu from "./LocalMenu";
import { Trans, useTranslation } from "react-i18next";
import { blue } from "@mui/material/colors";

const useStyles = makeStyles(() => ({
  navLink: {
    color: "white !important",
    "&.active": {
      textDecorationColor: "darkorange",
      textUnderlinePosition: "under",
      textDecoration: "underline",
      textDecorationThickness: "3px ",
    },
    marginTop: " 8px !important",
    marginBottom: " 8px !important",
    marginLeft: " 24px !important",
    marginRight: " 24px !important",
    "&:hover": {
      textDecorationColor: "darkorange !important",
      textDecorationStyle: "solid !important",
      textUnderlinePosition: "under !important",
      textDecorationThickness: "3px !important",
    },
  },
}));

function Header() {
  const classes = useStyles();
  const user = window.localStorage.getItem("user");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar
        position="static"
        elevation={0}
        sx={{
          // px: 10,
          backgroundColor: "#046d76",
          fontFamily: "open-sans",
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          {/* <IconButton> */}
          <Avatar
            variant="square"
            color="inherit"
            noWrap
            sx={{
              flexGrow: 1,
              textAlign: {
                lg: "inherit",
                md: "inherit",
                sm: "inherit",
                xs: "center",
              },
              maxWidth: "180px",
              height: "90%",
              py: 2,
              cursor: "pointer",
            }}
            src={Logo}
            onClick={() => {
              navigate("/");
            }}
          />
          {/* </IconButton> */}
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
            }}
          />

          <Box
            component="nav"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "left",
            }}
          >
            {/* <LocalMenu /> */}
          </Box>
          {user ? (
                        <>
                        <Box
                          sx={{
                            display: {
                              xs: "none",
                              sm: "flex",
                              md: "flex",
                              lg: "flex",
                            },
                          }}
                        >
                          <Link
                            variant="button"
                            fontSize={"medium"}
                            to="/"
                            component={NavLink}
                            underline="hover"
                            className={classes.navLink}
                          >
                            <Trans i18nKey="home">HOME</Trans>
                          </Link>
                          <Link
                            fontSize={"medium"}
                            variant="button"
                            to="blog"
                            component={NavLink}
                            underline="hover"
                            className={classes.navLink}
                          >
                            {t("blog")}
                          </Link>
                          <Link
                            variant="button"
                            fontSize={"medium"}
                            to="/pricing"
                            component={NavLink}
                            underline="hover"
                            className={classes.navLink}
                            color="inherit"
                          >
                            {t("price")}
                          </Link>
                 
                        </Box>
                        <IconButton
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          sx={{
                            display: {
                              lg: "none",
                              md: "none",
                              sm: "none",
                              xs: "flex",
                            },
                            justifyContent: "center",
                            color: "white",
                          }}
                        >
                          <MenuOutlined />
                        </IconButton>
                        <Box
                          sx={{
                            width: 12,
                            px: 0.5,
                          }}
                        >
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            sx={{
                              px: 2,
                              mx: 2,
                            }}
                          >
                            <MenuItem
                              variant="button"
                              fontSize={"medium"}
                              to="/"
                              component={NavLink}
                              underline="hover"
                              // className={classes.navLink}
                              color="inherit"
                              sx={{
                                color: "primary.light",
                                fontSize: 14,
                              }}
                            >
                              <Trans i18nKey="home">HOME</Trans>
                            </MenuItem>
                            <MenuItem
                              fontSize={"medium"}
                              variant="button"
                              to="blog"
                              component={NavLink}
                              underline="hover"
                              // className={classes.navLink}
                              color="inherit"
                              sx={{
                                color: "primary.light",
                                fontSize: 14,
                              }}
                            >
                              {t("blog")}
                            </MenuItem>
                            <MenuItem
                              variant="button"
                              fontSize={"medium"}
                              to="/pricing"
                              component={NavLink}
                              underline="hover"
                              // className={classes.navLink}
                              color="inherit"
                              sx={{
                                color: "primary.light",
                                fontSize: 14,
                              }}
                            >
                              {t("price")}
                            </MenuItem>
                            <Divider
                              sx={{
                                width: "100%",
                                height: "3px",
                                color: "gray",
                              }}
                            />
                            <MenuItem
                              sx={{
                                // backgroundColor: "gray",
                                borderRadius: "5px",
                                color: "primary.light",
                                fontSize: 14,
                              }}
                              onClick={() => navigate("/signup")}
                            >
                              {t("register")}
                            </MenuItem>
          
                            <MenuItem
                              sx={{
                                color: "primary.light",
                                fontSize: 14,
                              }}
                              onClick={() => navigate("/login")}
                            >
                              {t("login")}
                            </MenuItem>
                          </Menu>
                        </Box>
                        <AccountMenu />
                      </>
            
          ) : 
          <>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
                md: "flex",
                lg: "flex",
              },
            }}
          >
            <Link
              variant="button"
              fontSize={"medium"}
              to="/"
              component={NavLink}
              underline="hover"
              className={classes.navLink}
            >
              <Trans i18nKey="home">HOME</Trans>
            </Link>
            <Link
              fontSize={"medium"}
              variant="button"
              to="blog"
              component={NavLink}
              underline="hover"
              className={classes.navLink}
            >
              {t("blog")}
            </Link>
            <Link
              variant="button"
              fontSize={"medium"}
              to="/pricing"
              component={NavLink}
              underline="hover"
              className={classes.navLink}
              color="inherit"
            >
              {t("price")}
            </Link>
            <Button
              sx={{
                backgroundColor: "whitesmoke",
                color: "secondary.light",
                px: 2,
                ":hover": {
                  color: "secondary.light",
                },
              }}
              onClick={() => navigate("/signup")}
            >
              {t("register")}
            </Button>
            <Button
              sx={{
                backgroundColor: "whitesmoke",
                color: "secondary.light",
                px: 2,
                mx: 2,
              }}
              onClick={() => navigate("/login")}
            >
              {t("login")}
            </Button>
          </Box>
        
      
         
          
         
          
         
        
        </>}
          <button onClick={() => changeLanguage("en")}>EN</button>
          <button onClick={() => changeLanguage("am")}>AM</button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
