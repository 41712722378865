import {
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import {
  CheckCircle,
  Lock,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Link as RouterLink,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useAuth } from "../../../hooks/useAuth";
import { Logo2 } from "../../../assets";

function LoginPage() {
  const navigateTo = useNavigate();
  const { state } = useLocation();
  const user = window.localStorage.getItem("user");

  const [values, setValues] = React.useState({
    email: undefined,
    password: undefined,
    showPassword: true,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const {  mutate,isLoading,isError,isSuccess,error,data: response,} = useAuth();

  const handleSubmit = (e) => {

    e.preventDefault();
    mutate({
      username: values?.email,
      password: values?.password,
    });
  };

  return (
    <Container
      sx={{
        minHeight: "inherit",
        px: "0px !important",
      }}
      maxWidth="sm"
    >
      <Card
        elevation={10}
        component="form"
        sx={{
          mt: 5,
          minHeight: "50%",
          justifySelf: "center",
        }}
      >
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "inherit", mt: 4 }}
          rowGap={4}
        >
          <Grid item xs={10}>
            <img
              src={Logo2}
              alt="Amaklo Logo"
              style={{ height: "140px", width: "auto" }}
            />
          </Grid>
          <Grid item xs={12} sx={{ minWidth: "60%" }}>
            <TextField
              required
              fullWidth
              type="email"
              autoFocus
              id="email"
              placeholder="Enter Email Address"
              name="email"
              value={values.email}
              autoComplete="email"
              variant="outlined"
              onChange={handleChange("email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <CheckCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ minWidth: "60%" }}>
            <TextField
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              placeholder="********"
              required
              fullWidth
              id="password"
              name="password"
              autoComplete="password"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sx={{ minWidth: "60%" }} alignSelf="center">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{
                mt: 3,
                mb: 3,
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
              onClick={handleSubmit}
            >
              LOG IN
            </Button>

            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              sx={{ rowGap: 3 }}
            >
              <Link
                variant="button"
                to="/signup"
                component={RouterLink}
                underline="none"
                sx={{ color: "#00334E", fontWeight: "600" }}
              >
                CREATE AN ACCOUNT
              </Link>
              <Link
                variant="button"
                to="/reset-password"
                component={RouterLink}
                underline="none"
                sx={{
                  color: "#00334E",
                  fontWeight: "600",
                }}
              >
                FORGOT PASSWORD? RESET
              </Link>
            </Box>
          </Grid>
          <Grid item mt={5} />
          <Grid item mt={5} />
        </Grid>
      </Card>
    </Container>
  );
}

export default LoginPage;
