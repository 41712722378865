import {
  AllInbox,
  ArrowBackIosNewOutlined,
  ArrowForwardIos,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useRef } from "react";
import { Amaklo, General } from "../../../assets";
import { CategoryContext } from "../../containers/home";
const CategoryItem = ({ name, Icon, categoryRef }) => {
  const { setCategoryRef } = React.useContext(CategoryContext);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // rowGap: 2,
        width: "200px",
        height: "100px",
        alignItems: "center",
        // mx: 2,
        // backgroundColor: "inherit",
        boxShadow: 1.5,
        justifyContent: "center",
        m: {
          xs: 1,
        },
        px: 4,
        // py: 7,
        py: 2,

        borderBottom:
          window.localStorage.getItem("category") === categoryRef ? 3 : 0,
        color: theme.palette.primary.main,

        "&:hover": {
          borderBottom: 3,
          color: theme.palette.primary.main,

          // color: "white",
          fontWeight: "medium",
          // borderRadius: "10px",
          // transform: "scale(1.1)",
        },
      }}
      onClick={() => {
        setCategoryRef(categoryRef);
      }}
    >
      <Avatar
        src={Icon}
        variant="square"
        alt="Amaklo"
        sx={{
          width: "50px",
          height: "50px",
          objectFit: "contain",
        }}
      />
      <Typography
        sx={{
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

function Categories() {
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery(
    ["category"],
    () => {
      return axios.get(
        `https://api.amaklo.com/amakloapi/_v1/categories/all`
      );
    },
    {
      refetchOnWindowFocus: true,
      enabled: true,
    }
  );
  const ref = useRef();
  let category = data?.data;

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };
  return (
    <Box
      sx={{
        // backgroundColor: "whitesmoke",
        // mt: 5,
        overflowX: "hidden",
      }}
    >
      <Grid container>
        {/* <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4">CATEGORIES</Typography>
          <Typography
            variant="subtitle"
            sx={{
              textAlign: "center",
            }}
          >
            Filter Your Search With Specific Category Available To Choose From
          </Typography>
        </Grid>
         */}
        <Box
          sx={{
            mb: 2,
            display: "flex",

            width: "100%",
          }}
        >
          <IconButton
            onClick={() => scroll(-1000)}
            sx={{
              color: "primary.main",
              borderRadius: "50%",
              width: "80px",
              height: "80px",
              alignSelf: "center",
            }}
          >
            <ArrowBackIosNewOutlined fontSize="large" />
          </IconButton>
          <Box
            ref={ref}
            sx={{
              display: "flex",
              width: "100%",
              py: 1,

              overflow: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
            justifyContent="space-between"
          >
            <CategoryItem name={"All"} Icon={General} categoryRef={""} />
            {category?.map((cat) => (
              <CategoryItem
                name={cat?.category_name}
                Icon={cat?.category_icon || Amaklo}
                categoryRef={cat?._id}
              />
            ))}
          </Box>
          <IconButton
            onClick={() => scroll(+1000)}
            sx={{
              color: "primary.main",
              borderRadius: "50%",
              width: "80px",
              height: "80px",
              alignSelf: "center",
            }}
          >
            <ArrowForwardIos fontSize="large" />
          </IconButton>
        </Box>
      </Grid>
    </Box>
  );
}

export default Categories;
