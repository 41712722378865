import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Divider,
  Icon,
  TextField,
  InputAdornment,
  IconButton,
  Skeleton,
  Chip,
  CircularProgress,
} from "@mui/material";
import {
  ChatBubbleOutlineOutlined,
  Favorite,
  FavoriteBorderOutlined,
  FavoriteBorderRounded,
  Send,
  ThumbDownSharp,
  ThumbsUpDown,
  ThumbUpAltRounded,
  VisibilitySharp,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useCount } from "../../../hooks/api";

const BlogDetail = () => {
  const param = useParams();
  const user = localStorage.getItem("user");
  const [text, setText] = React.useState();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: count } = useCount();
  const [view ,setView] =useState(false)

  const [likebtn,setlikebtn ] =useState(null)

  const [favbtn,setfavbtn ] =useState(null)

  React.useEffect(() => {
    count({
      _clicked: "blog",
      _clickedtype: "blog details",
      _ref: param?.blogId,
    });
  }, [param?.blogId]);

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery(
    ["singleblog"],
    () => {
      return axios.get(
        `https://api.amaklo.com/amakloapi/_v1/blogs/openfetchsingle/${param?.blogId}`
      );
    },
    {
      refetchOnWindowFocus: true,
      enabled: true,
    }
  );

 
  React.useEffect(() => {
    refetch();
  }, []);
  const {
    mutate: makeFavorite,
    isLoading: favoriteLaoding,
    isSuccess: favored,
  } = useMutation(
    (action) => {
       console.log(likebtn  +''+ favbtn)
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/blogs/react/${param?.blogId}`,
        action,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
         
        if(favbtn){
          enqueueSnackbar(`Favorite the blog `, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
         
        }
        else{
          enqueueSnackbar(`Not in Favorite like`, {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }

 
        refetch();
          
     


      },
      onError: () => {
             
        if(!favbtn){
          enqueueSnackbar(`Not the blog `, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
  
      },
    }
  );
  const {
    mutate: makeLike,
    isLoading: LikeLaoding,
    isSuccess: Like,
  } = useMutation(
    (action) => {
    
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/blogs/react/${param?.blogId}`,
        action,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
         
        if(likebtn){
          enqueueSnackbar(`Liked the blog `, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
         
        }
        else{
          enqueueSnackbar(`Not like`, {
            variant: "warning",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }

 
        refetch();
          
     


      },
      onError: () => {
             
        if(!likebtn){
          enqueueSnackbar(`Login first `, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
  
      },
    }
  );
  React.useEffect(() => {
    if (favored) {
      refetch();
    }
  }, [favored, refetch]);
  const {
    mutate: postComment,
    isLoading: commenting,
    isSuccess: commented,
    refetch: refetchComment,
  } = useMutation(
    (comment) => {
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/blogs/comment/${param?.blogId}`,
        comment,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        refetch();
        setText("");
      },
    }
  );
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        color={"primary.main"}
        backgroundColor={"primary.main"}
        width={"100%"}
        height={500}
        animation={"wave"}
      />
    );
  }

 
console.log(data)
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
            flexDirection: "column",
          }}
        >
          <Box          sx={{
            display: "flex",
            justifyContent: 'start',
            alignItems:'center',
            p: 2,
            
          }}>
         
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: 900,
              my: 1,
            }}
          >
            {data?.data?.blog_title}
          </Typography>
          </Box>
        

          <Box
            sx={{
              maxHeight: "2.3125rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={data?.data.business_ref?.business_avatar}
                alt={"Business name"}
              />
              <Typography sx={{ ml: 2 }}>
                {data?.data.business_ref?.business_name}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mx: 2,
              }}
            >
              <IconButton
                disabled={!window.localStorage.getItem("user")}
                sx={{
                  p: 2,
                  color: "primary.main",
                }}
                onClick={() => {
                  makeFavorite({
                    action: "favorite",
                  }) 
                  return setfavbtn(!favbtn);
                }}
              >
                {favoriteLaoding ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    {data?.data?.favourites.find(
                      (f) =>
                        f?.user_id === window.localStorage.getItem("userId")
                    ) ? (
                      <Favorite />
                    ) : (
                      <FavoriteBorderOutlined />
                    )}
                  </>
                )}
              </IconButton>
            </Box>
          </Box>

          <Box
            component={"img"}
            sx={{
              maxWidth: "100%",
              maxHeight: "400px",
              my: 2,
            }}
            src={data?.data?.cover_image}
          />
        </Box>
        <Typography
          sx={{
            maxWidth: "61.5625rem",
            minHeight: "15.75rem",
            p: 2,

            textAlign: {
              lg: "justify",
              md: " justify",
              xs: "justify",
            },
            font: "normal normal normal 27px/42px Roboto",
            letterSpacing: " 0.0813rem",
            fontSize: {
              lg: "1.3rem ",
              xs: "1rem",
            },
            color: "#000000",
          }}
        >
          {data?.data?.blog_content}
          <Box
            sx={{
              display: "flex",
              height: "5.6875rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Divider
              orientation="vertical"
              sx={{
                backgroundColor: "black",
                height: "1.9375rem",
                width: "0.3125rem",
                m: 2,
              }}
            />
            <Typography
              sx={{
                font: "italic  1.2rem/1.7rem Montserra",
              }}
            >
              AMAKLO DIGITAL ADVERTISING THAT WORKS AS HARD AS YOU DO
            </Typography>
          </Box>
        </Typography>
        <Box
          sx={{
            display: "flex",
            mx: 1,
            alignItems: "center",
          }}
        >
          {data?.data?.tags?.map((tag, index) => {
            return (
              <Chip
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  p: 2,
                  mx: 1,
                  boxShadow: 1,
                  fontSize: 15,
                }}
                key={index}
                label={`${tag}`}
              />
            );
          })}
        </Box>
        <Box
          sx={{
            maxWidth: "100%",
            
             transition:'0.5s',
            overflow:'hidden',
            position:'relative'
          }}
         style={{height:view ? "auto" :'450px' }} 
        >
          <Box      sx={{
            minWidth: "100%",
          
            height:'40px',

            overflow:'hidden',
            position:'absolute',
            bottom:'-2px',
            zIndex:'1000',
            display:'flex',
            alignItems:'center',
            justifyContent:'center' 
          }} className="viewmore" >
            
            <button className="view-more" onClick={()=>{setView(!view)}}>{view ? 'Close':'View more' }</button>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                maxHeight: "22px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label="share"
                  disabled={!window.localStorage.getItem("user")}
                  onClick={() => {
                    makeLike({
                      action: "like",
                    })  && refetch();
                    return setlikebtn(!likebtn);
         
                  }}
                >
                  {favoriteLaoding ? (
                    <CircularProgress size={20} />
                  ) : (
                    <>
                      {data?.data?.likes.find(
                        (f) =>
                          f?.user_id === window.localStorage.getItem("userId")
                      ) ? (
                        <ThumbUpAltRounded />
                      ) : (
                        <ThumbUpAltRounded />
                      )}
                    </>
                  )}
                  <Typography
                    sx={{
                      display: "flex",
                      ml: 2,
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    {data?.data?.likes?.length}
                  </Typography>
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: 2,
                }}
              >
                <Icon>
                  <ChatBubbleOutlineOutlined />
                </Icon>
                <Typography sx={{ m: 2 }}>
                  {data?.data?.comments?.length}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: {
                    md: "flex",
                    sm: "none",
                    xs: "none",
                  },
                  alignItems: "center",
                }}
              >
                <VisibilitySharp sx={{ mx: 1 }} />

                <Typography>{data?.data?.blog_views}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: 3,
              }}
            >
              <Box
                sx={{
                  width: "0.4375rem",
                  height: "0.4375rem",
                  borderRadius: "50%",
                  background: "#4E4E4E 0% 0% no-repeat padding-box",
                  mr: 1,
                }}
              />
              <Typography
                sx={{
                  overflow: "hidden",
                }}
              >
                {moment(data?.data?.posted_date).format("lll")}
              </Typography>
            </Box>
          </Box>
          <Divider
            variant="middle"
            sx={{
              backgroundColor: "white",
              mb: 3,
              height: "0.0225rem",
            }}
          />
          {user && (
            <Box
              sx={{
                p: 3,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 900,
                }}
              >
                Comments
              </Typography>
              <TextField
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          postComment({
                            comment: text,
                          });
                        }}
                      >
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                sx={{
                  p: 1,
                }}
                // label="Add comment here  "
                variant="standard"
                placeholder="Add comment here"
              />
            </Box>
          )}
          {data?.data?.comments?.map((blog, index) => {
            return (
              <>
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    maxWidth: "61.5625rem",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                   

                    p: 2,
                  
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                  
                    <Avatar alt="Amaklo" src={blog?.user_avatar
} />
                    <Typography
                      sx={{
                        letterSpacing: " 1px",
                        fontWeight: 700,
                        color: "#707070",
                        ml: 1,
                      }}
                    >
                      {blog?.user_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        ml: 2,
                        letterSpacing: "0.8px",
                        color: "#313131",
                      }}
                    >
                      {moment(blog?.comment_at).format("lll")}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontStyle: "italic",
                      alignItems: "center",
                      ml: 6,
                    }}
                  >
                    {blog?.comment}
                  </Typography>
                  <Divider
                    variant="fullWidth"
                    sx={{
                      backgroundColor: "gray",
                      my: 1,
                    }}
                  />
                </Box>
              </>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default BlogDetail;
