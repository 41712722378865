import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Blogimg from "../../../assets/images/imgblog.jpg";
import moment from "moment";
import { ThumbUpAltRounded, Visibility } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function BlogItemCard({ blog, refetch }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    mutate: makeFavorite,
    isLoading: favoriteLaoding,
    isSuccess: favored,
  } = useMutation(
    (action) => {
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/blogs/react/${blog?._id}`,
        action,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar("Liked the blog ", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
      onError: () => {
        enqueueSnackbar("login to like the blog ", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );
  React.useEffect(() => {
    if (favored) {
      refetch();
    }
  }, [favored, refetch]);

  return (
    <Grid item xs={12} md={4}>
      <Card sx={{ maxWidth: 345, borderRadius: "10px" }} elevation={12}>
        <CardHeader
          avatar={
            <Avatar
              sx={{ bgcolor: "primary.main" }}
              aria-label="recipe"
              alt="Amaklo"
            />
          }
          // action={          }
          // title={blog?.blog_title}
          subheader={moment(blog?.posted_date).format("lll")}
        />
        <CardMedia
          component="img"
          height="194"
          src={blog?.cover_image || Blogimg}
          alt="Paella dish"
        />
        <CardContent>
          <Typography color="text.primary" fontWeight={600} textAlign={"left"}>
            {blog?.blog_title}
          </Typography>
          <br />
          <Typography variant="body2" color="text.primary">
            {blog?.blog_content?.substring(0, 200)}
            <Typography
              variant="span"
              sx={{
                fontSize: "22px",
                fontWeight: 200,
              }}
            >
              {"    ...."}
            </Typography>
          </Typography>
        </CardContent>
        <CardActions
          disableSpacing
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            disabled={!window.localStorage.getItem("user")}
            aria-label="add to favorites"
            onClick={() =>
              makeFavorite({
                action: "favorite",
              })
            }
          >
            <FavoriteIcon color="primary" />
            <Typography
              sx={{
                display: "flex",
                ml: 2,
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              {blog?.favourites?.length}
            </Typography>
          </IconButton>
          <IconButton
            aria-label="share"
            disabled={!window.localStorage.getItem("user")}
            onClick={() => {
              makeFavorite({
                action: "like",
              }) && refetch();
            }}
          >
            <ThumbUpAltRounded
              sx={{
                color: "#C25E90",
              }}
            />
            <Typography
              sx={{
                display: "flex",
                ml: 2,
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              {blog?.likes?.length}
            </Typography>
          </IconButton>
          <Box sx={{ display: "flex" }}>
            <Visibility sx={{ mx: 1 }} />
            <Typography>{blog?.blog_views}</Typography>
          </Box>
          <ExpandMore
            // expand={expanded}
            onClick={() => navigate(`/blog-details/${blog._id}`)}
            // aria-expanded={expanded}
            aria-label="show more"
          >
            <DoubleArrowIcon />
          </ExpandMore>
        </CardActions>
      </Card>
    </Grid>
  );
}
