import { Check, CheckCircle } from "@mui/icons-material";
import PuffLoader from "react-spinners/PuffLoader";
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    minWidth: 200,
    minHeight: 850,
    margin: 5,
    elevation: 5,
    textAlign: "center",
  },
  header: {
    textAlign: "center",
    spacing: 10,
  },
  list: {
    padding: "40px",
  },
  button: {
    margin: theme.spacing(1),
  },
  action: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

function Priceing() {
  const [Price_info, setPrice_info] = useState([])

  function price() {
    return new Promise((resolve, reject) => {


      axios.get('https://api.amaklo.com/amakloapi/_v1/package/paginate').then((res) => {
        resolve(setPrice_info(res.data.docs))
      }).catch((error) => {
        reject(console.log(error))
      })

    })
  }

  async function priceasync() {
    try {
      await price()
    }
    catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    priceasync()
  }, [])


  const classes = useStyles();
  const { t, i18n } = useTranslation();
  console.log(Price_info)

if(Price_info.length === 0){
      return <div className="w-full h-full flex items-center justify-center "> <PuffLoader color="#046d76" /></div>
}
else{
  return  <div className="w-full h-auto flex justify-center items-center">

  <div className="grid md:grid-cols-3   place-items-center gap-8 ">
    <div className="bg-slate-300 rounded-lg my-3 shadow-lg" >
    <CardHeader title={Price_info[0].packageTitle} className={classes.header} />
    <Typography
      sx={{ my: 2, fontWeight: "bold" }}
      variant="h4"
      align="center"
    >
                {Price_info[0].packagePrice + 'Birr'}    
    </Typography> 
    
    
     {
Price_info[0].description.map((dis,diskey)=>{
  return <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <CheckCircle
          fontSize="small"
          sx={{
            color: "primary.main",
            my: 0,
            ml:4
          }}
        />
        <Typography
          align="start"
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "primary.main",
            my: 1,
            pr:2
        
            
          }}
         
        >
          {dis}
        </Typography>
      </Box>
  

})
     }
  
    
    
        
    
    </div>

    <div className="bg-slate-300 rounded-lg my-3 shadow-lg" >
    <Box
      sx={{
        width: "100%",
        borderColor: "red.300",
        borderTopLeftRadius: 12,
        bgcolor: "#006E5E",
        color: "#FF8B41",
        fontWeight: "bold",
        py: 1,
      }}
    >
          <Typography
          align="center"
          sx={{
            fontSize: "15px",
            fontWeight: "bold",
            color: "orange",

            my: 1,
            pr:2
        
            
          }}
         
        >
   Recommended
        </Typography>

    </Box>
    <CardHeader title={Price_info[1].packageTitle} className={classes.header} />
    <Typography
      sx={{ my: 2, fontWeight: "bold" }}
      variant="h4"
      align="center"
    >
                {Price_info[1].packagePrice + 'Birr'}    
    </Typography> 
    
    
     {
Price_info[1].description.map((dis,diskey)=>{
  return <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <CheckCircle
          fontSize="small"
          sx={{
            color: "primary.main",
            my: 0,
            ml:4
          }}
        />
        <Typography
          align="start"
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "primary.main",
            my: 1,
            pr:2
        
            
          }}
         
        >
          {dis}
        </Typography>
      </Box>
  

})
     }
  
    
    
        
    
    </div>
    <div className="bg-slate-300 rounded-lg my-3 shadow-lg" >
    <CardHeader title={Price_info[2].packageTitle} className={classes.header} />
    <Typography
      sx={{ my: 2, fontWeight: "bold" }}
      variant="h4"
      align="center"
    >
                {Price_info[2].packagePrice + 'Birr'}    
    </Typography> 
    
    
     {
Price_info[2].description.map((dis,diskey)=>{
  return <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <CheckCircle
          fontSize="small"
          sx={{
            color: "primary.main",
            my: 0,
            ml:4
          }}
        />
        <Typography
          align="start"
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "primary.main",
            my: 1,
            pr:2
        
            
          }}
         
        >
          {dis}
        </Typography>
      </Box>
  

})
     }
  
    
    
        
    
    </div>
    
  </div>
</div>
}


}


export default Priceing;
