import { ExpandMoreRounded, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import BlogItemCard from "../../components/blog/BlogItem";
import Advert from "../../components/home/Advert";
import { Trans, useTranslation } from "react-i18next";

function Blogs() {
  const [searchText, setSearchText] = React.useState("");
  const [perPage, setPerPage] = React.useState(6);
  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery(
    ["blog"],
    () => {
      return axios.get(
        `https://api.amaklo.com/amakloapi/_v1/blogs/openpaginate?per_page=${perPage}`,
        {
          params: {
            search_key: searchText,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: true,
      enabled: true,
    }
  );
  React.useEffect(() => {
    refetch();
  }, [searchText, perPage, refetch]);

  const { t, i18n } = useTranslation();
  return (
    <Box mb="5%">
      <Advert text={searchText} searchText={setSearchText} title="Blog Title" />
      <Box my={5} />
      <Container maxWidth="lg">
        {data && (
          <Grid container spacing={5} rowGap={10}>
            {data?.data?.docs?.map((blog, index) => {
              return <BlogItemCard blog={blog} key={index} refetch={refetch} />;
            })}
          </Grid>
        )}
        {isLoading && (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={148}
            animation={"wave"}
          />
        )}{" "}
        {isSuccess && (
          <Box mt={5} display="flex" justifyContent="center">
            <Button
              sx={{ alignSelf: "center", bgcolor: "#E6F4F1" }}
              onClick={() => setPerPage(perPage + 3)}
              startIcon={
                isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <ExpandMoreRounded
                    fontSize="large"
                    sx={{
                      color: "primary.main",
                    }}
                  />
                )
              }
            >
              {t("moreblog")}
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default Blogs;
