import { Box, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { theme } from "../../theme";
import BlogDetail from "../components/blog/BlogDetail";
import Faq from "../components/Faq";
import GetInTouch from "../components/GetInTouch";
import Priceing from "../components/Price";
import LoginPage from "../containers/auth/Login";
import SignUpPage from "../containers/auth/SignUp";
import Blogs from "../containers/blogs";
import Home from "../containers/home";
import BottomNav from "../layout/Footer";
import Header from "../layout/Header";
import ProtectedWrapper from "../layout/ProtectedWrapper";
import Layout from "../layout/Home";
import AboutUs from "../layout/AboutUs";
import ForgetPassword from "../containers/auth/ResetPswd";
import Profile from "../components/auth/Profile";
import Favorite from "../components/Favoite";
import Privacy from "../layout/Privacy";
import Terms from "../layout/Terms";

function PageRoutes() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Header />
        <Box
          // component="main"
          sx={{
            flexGrow: 1,
            minHeight: "100vh",
            fontFamily: "open-sans",

            // p: 2,
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "space-between ",
          }}
        >
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/reset-password" element={<ForgetPassword />} />
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={
                  // <ProtectedWrapper>
                  <Home />
                  // </ProtectedWrapper>
                }
              />
              <Route
                path="/blog"
                element={
                  // <ProtectedWrapper>
                  <Blogs />
                  // </ProtectedWrapper> */
                }
              />{" "}
              <Route
                path="/blog-details/:blogId"
                element={
                  // <ProtectedWrapper>
                  <BlogDetail />
                  // </ProtectedWrapper>
                }
              />{" "}
              <Route
                path="/faq"
                element={
                  // <ProtectedWrapper>
                  <Faq />
                  // </ProtectedWrapper>
                }
              />
              <Route
                path="/profile"
                element={
                  // <ProtectedWrapper>
                  <Profile />
                  // </ProtectedWrapper>
                }
              />
              <Route
                path="/pricing"
                element={
                  // <ProtectedWrapper>
                  <Priceing />
                  // </ProtectedWrapper>
                }
              />
              <Route path="/contact-us" element={<GetInTouch />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/favorite" element={<Favorite />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
            </Route>
          </Routes>
        </Box>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default PageRoutes;
