import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import {
  CorporateFare,
  Lock,
  Person,
  PersonAdd,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Link as RouterLink,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import FileUpload from "../../components/auth/FileUpload";
import BusinessRegisteration from "./RegisterBusiness";
import styled from "@emotion/styled";
import { Logo2 } from "../../../assets/index";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 68,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "primary.main",
      transform: "translateX(30px)",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 32,
    height: 32,
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
  },
}));

function SignUpPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = React.useState({
    fullName: undefined,
    phone: undefined,
    email: undefined,
    password: undefined,
    confirmPassword: undefined,
    showPassword: false,
    profile: null,
  });

  const handleChange = (prop) => (event) => {
    if (prop === "phone" || prop === "profile")
      return setValues({ ...values, [prop]: event });
    setValues({ ...values, [prop]: event.target.value });
  };
  console.log(handleChange);
  const handleClickShowPassword = () => {
    console.log(values);
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const location = useLocation();
  console.log(location.state?.isBusiness);
  const [value, setValue] = React.useState(
    false
  );
  const handleSwitchChange = () => {
    console.log(value);
    setValue(!value);
  };

  // if (user) return <Navigate to="/" replace={true} />;
  const {
    mutate: signUp,
    isLoading,
    isError,
    isSuccess,
  } = useMutation(
    (data) => {
      return axios.post(
        "https://api.amaklo.com/amakloapi/_v1/auth/customersignup",
        data
      );
    },
    {
      onSuccess: () => {
        navigate("/login");
      },
      onError: (error) => {
        enqueueSnackbar(`${error.response.data.message}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    signUp({
      email: values.email,
      phone_number: values.phone,
      name: values.fullName,
      password: values.password,
      // confirmPassword: values.confirmPassword,
    });
    // localStorage.setItem("user", "Authenticated");
    // navigateTo(state?.path || "/");
  };

  return (
    <Container
      sx={{
        px: "0px !important",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
      }}
      // maxWidth="sm"
    >
      <Grid item xs={10}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <img
            src={Logo2}
            alt="Amaklo Logo"
            style={{ height: "140px", width: "auto", marginRight: "100px" }}
          />
          <Box>
            <Typography
              variant="h5"
              component="h3"
              fontWeight="bolder"
              sx={{ color: "#00334E" }}
            >
              WELCOME!
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#00334E" }}>
              Join AMAKLO now
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Divider
        sx={{
          width: "50%",
          borderBottomWidth: 5,
          color: "#00334E",
          mt: 4,
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 7,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mx: 1,
          }}
        >
          <PersonAdd
            sx={{
              width: "30px",
              height: "30px",
              color: `${value === false ? "primary.main" : ""}`,
            }}
          />
          <Typography
            sx={{
              fontWeight: "bolder",
              fontSize: "20px",
              color: `${value === false ? "primary.main" : ""}`,
            }}
          >
            Personal
          </Typography>
        </Box>
        <MaterialUISwitch
          size={"large"}
          checked={value}
          onChange={handleSwitchChange}
          value={value}
          sx={{}}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mx: 1,
          }}
        >
          <CorporateFare
            sx={{
              width: "30px",
              height: "30px",
              color: `${value === true ? "primary.main" : ""}`,
            }}
          />
          <Typography
            sx={{
              fontWeight: "bolder",
              fontSize: "20px",
              color: `${value === true ? "primary.main" : ""}`,
            }}
          >
            Business
          </Typography>
        </Box>
      </Box>
      {value === false ? (
        <Box component="form" sx={{ minHeight: "inherit", my: 5 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "inherit" }}
            rowGap={4}
          >
            <Grid item xs={12} sx={{ minWidth: "100%" }}>
              <TextField
                required
                autoFocus
                fullWidth
                id="fullName"
                placeholder="Full Name"
                name="fullName"
                autoComplete="fullName"
                variant="outlined"
                onChange={handleChange("fullName")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ minWidth: "100%" }}>
              <TextField
                required
                fullWidth
                id="email"
                placeholder="Enter Email Address"
                name="email"
                autoComplete="email"
                variant="outlined"
                onChange={handleChange("email")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ minWidth: "90%" }}>
              <MuiPhoneNumber
                fullWidth
                defaultCountry={"et"}
                onChange={handleChange("phone")}
              />
            </Grid>

            <Grid item xs={12} sx={{ minWidth: "100%" }}>
              <TextField
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                placeholder="Password"
                required
                fullWidth
                id="password"
                name="password"
                autoComplete="password"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ minWidth: "100%" }}>
              <TextField
                type={values.showPassword ? "text" : "password"}
                value={values.confirmPassword}
                onChange={handleChange("confirmPassword")}
                placeholder="Confirm Password"
                required
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                autoComplete="password"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FileUpload onChange={handleChange("profile")} />
            </Grid> */}

            <Grid item xs={12} sx={{ minWidth: "100%" }} alignSelf="center">
              <Button
                type="submit"
                fullWidth
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 3,
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                }}
                disabled={
                  isLoading || values.password !== values.confirmPassword
                }
              >
                Register
              </Button>

              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                sx={{ rowGap: 3 }}
              >
                <Link
                  variant="button"
                  to="/login"
                  component={RouterLink}
                  underline="none"
                  sx={{ color: "#00334E", fontWeight: "600" }}
                >
                  ALREADY REGISTERED? LOGIN
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
          <Paper
            // variant="outlined"
            elevation={0}
            sx={{
              my: { xs: 3, md: 6 },
              p: { xs: 2, md: 3 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h6"
              sx={{
                fontWeight: 500,
                textDecoration: "underline",
                my: 2,
              }}
            >
              Register Your business
            </Typography>
            <BusinessRegisteration />
          </Paper>
        </Container>
      )}
    </Container>
  );
}

export default SignUpPage;
