import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Link,
  Rating,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  EmailOutlined,
  Facebook,
  Instagram,
  LanguageOutlined,
  LinkedIn,
  LiveTvOutlined,
  PhoneCallback,
  Telegram,
  Twitter,
  VideoCallSharp,
  YouTube

} from "@mui/icons-material";
import ReactPlayer from "react-player";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useCount } from "../../../hooks/api";
import { SocialIcon } from 'react-social-icons';

const logos = [
  <LinkedIn />,
<SocialIcon network="tiktok" bgColor="#046d76"  fgcolor="#046d76" style={{ height: 25, width: 25 }} />,
<SocialIcon network="snapchat" bgColor="#046d76" style={{ height: 25, width: 25 }} fgcolor="#046d76" />,
  <Facebook />,
  <Twitter />,
  <Instagram />,
  <Telegram />,
  <YouTube />,
  <LiveTvOutlined />,
  <VideoCallSharp />,
];

function NavLink({ link, icon, name, onCount }) {


  return (
    <Link
      sx={{
        color: "primary.main",
        fontSize: "15px",
        my: 3,
        mx: 5,
      }}
      href={`https://${link} `}
      target="_blank"
      underline="hover"
      onClick={onCount}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "30px",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{
            color: "primary.main",
            fontSize: "26px",
            marginBottom: "10px",
          }}
        >
          {icon}
        </IconButton>
        <span>{name}</span>
      </Box>
    </Link>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BusinessDetailTab({ data }) {
  //get all ratings
  let allRating = data?.business_rates;
  // find rating of current loged in user
  let userRate = allRating?.find(
    (r) => r?.user_id === window.localStorage.getItem("userId")
  );
  // console.log(userRate?.rate);


  console.log(data)
  const [value, setValue] = React.useState(0);
  const [rating, setRating] = React.useState(userRate?.rate);
  const [review, setReview] = React.useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let pos = {
    lat: 8.955990478787927,
    lng: 38.76761824688026,
  };
  const { lat, lng } = pos;
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate: rateReview,
    isLoading,
    isSuccess: rateReveiwSuccess,
  } = useMutation(
    ({ review, rate }) => {
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/businesses/review/${data?._id}`,
        { review, rate },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Your review is sent successfully ", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );

  const {
    mutate: rateBusiness,
    refetch,
    isLoading: rateLoading,
    isSuccess: rateSuccess,
  } = useMutation(
    ({ rate, action }) => {
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/businesses/react/${data?._id}`,
        { rate, action },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Your rate is sent successfully ", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );
  const { mutate: count } = useCount();
  const onRatingChanging = (rating) => {
    rateBusiness({
      action: "rate",
      rate: rating,
    });
  };
  React.useEffect(() => {
    if (rating !== 0 && rating !== userRate?.rate) {
      onRatingChanging(rating);
    }
  }, [rating, userRate?.rate]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", maxWidth: "inherit" }}
        display="flex"
        justifyContent="space-between"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          sx={{ width: "100%" }}
        >
          <Tab label="Detail" {...a11yProps(0)} />
          <Tab label="Review" {...a11yProps(1)} />
          <Tab label="Services" {...a11yProps(2)} />
          <Tab label="Contact" {...a11yProps(3)} />
          <Tab label="Address" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <Rating
              size="large"
              name="simple-controlled"
              value={data?.rates}
              readOnly
              // onChange={(event, newValue) => {
              //   setRating(newValue);
              // }}
              sx={{
                my: 2,
              }}
            />
            {/* <Button
              sx={{
                fontSize: "15px",
                textAlign: "left",
                textDecoration: "capitalize",
                fontWeight: 900,
                border: 1,
                mx: 2,
              }}
              disabled={!localStorage.getItem("user")}
              onClick={() => {
                rateBusiness({
                  action: "rate",
                  rate: rating,
                });
              }}
            >
              Rate this business
            </Button> */}
          </Box>
        
          <Typography
            sx={{
              fontSize: "15px",
              textAlign: "center",
              fontWeight: 600,
              direction:'coloumn',
              my: 2,
            }}
          >
            {
             data?.about
               
            || "Details is not provided"}
          </Typography>  
           
          {/* <ReactPlayer url="https://youtu.be/ScMzIvxBSi4" /> */}
        </Box>
      </TabPanel>{" "}
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "80%",
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                textAlign: "left",
                fontWeight: 900,
                mx: 2,
              }}
            >
              Give your rating
            </Typography>
            <Rating
              size="large"
              name="simple-controlled"
              // defaultValue={userRate?.rate}
              value={rating}
              readOnly={!window.localStorage.getItem("user")}
              onChange={(event, newValue) => {
                setRating(newValue);
                // onRatingChanging(rating);
              }}
              sx={{
                my: 2,
              }}
            />{" "}
          </Box>
          <TextField
            variant="standard"
            fullWidth
            multiline
            onChange={(e) => setReview(e.target.value)}
            placeholder="Leave your feedback  for this business"
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              backgroundColor: "#00000026",
              borderRadius: 2,
              my: 2,
              p: 5,
              fontSize: "19px",
            }}
          />
          <Button
            sx={{
              px: 4,
              my: 3,
              bgcolor: "primary.main",
              color: "white",
              textDecoration: "capitalize",
            }}
            variant="contained"
            disabled={!review || isLoading || !localStorage.getItem("user")}
            onClick={() =>
              rateReview({
                review: review,
                rate: rating,
              })
            }
          >
            Submit your review
          </Button>
        </Box>
        {data?.business_reviews?.map(({ user_name, review, rate,user_avatar }, index) => {
          return (
            <>
              <Box
                key={index}
                sx={{
                  display: "flex",
                  backgroundColor: "whitesmoke",
                  // flexDirection: "column",
                  alignItems: "center",
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar src={user_avatar} alt="Am" />
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      mx: 3,
                      color: "primary.main",
                    }}
                  >
                    {user_name} {" :"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    py: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                    }}
                  >
                    {review}
                  </Typography>
                  <Rating
                    size={"small"}
                    name="read-only"
                    value={rate}
                    readOnly
                  />
                </Box>
              </Box>
              <Divider
                sx={{
                  // mx: 3,
                  my: 1,
                }}
              />
            </>
          );
        })}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 900,
              color: "primary.main",
            }}
          >
            Services we provide
          </Typography>
          <Grid container columnGap={1} justifyContent="left">
            {data?.business_services?.map((service) => {
              return (
                <Grid item lg={3.7}>
                  <Tooltip
                    sx={{ color: "primary.main" }}
                    title={service?.service_desc}
                    arrow
                  >
                    <Card
                      elevation={12}
                      sx={{
                        height: "200px",
                        width: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyItems: "center",
                        justifyContent: "center",
                        backgroundImage: `url(${service?.service_image})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        m: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          bgcolor: "rgb(4, 109, 118,0.7)",
                        }}
                      >
                        <Typography
                          gutterBottom
                          fontWeight={"bold"}
                          sx={{ textAlign: "center" }}
                        >
                          {service?.service_name}
                        </Typography>
                      </Box>
                    </Card>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              width: "50%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                color: "primary.main",
              }}
            >
              <PhoneCallback
                sx={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  mx: 2,
                }}
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                }}
              >
                {data?.phone_number}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "primary.main",
                my: 1,
              }}
            >
              <EmailOutlined
                sx={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  mx: 2,
                }}
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                }}
              >
                {data?.email}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                color: "primary.main",
                justifyContent: "center",
                alignItems: "center",
                my: 1,
              }}
            >
              <LanguageOutlined
                sx={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "primary.main",
                  mx: 2,
                }}
              />
              <Link href={`${data?.website}`}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "400",
                  }}
                >
                  {data?.website}
                </Typography>
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              my: 1,
            }}
          >
            {data?.social_links?.map((social, i) => {
              return (
                <NavLink
                  link={social?.social_link}
                  name={social?.social_name}
                  icon={logos[i]}
                  onCount={() => {
                    count({
                      _clicked: "social",
                      _clickedtype: {},
                      _ref: `${social.short_hand}`,
                    });
                  }}
                />
                // )
              );
            })}
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "500",
            textAlign: "center",
            color: "primary.main",
          }}
        >
          {data?.address}
        </Typography>

        <Box
          sx={{
            minWidth: "100%",
            minHeight: "400px",
          }}
          component={"iframe"}
          src={`https://maps.google.com/maps?q=${data?.location.latitude}, ${data?.location.longitude}&z=15&output=embed`}
          style={{ border: 0 }}
          // allowfullscreen=""
          loading="eager"
          referrerpolicy="no-referrer-when-downgrade"
        ></Box>
      </TabPanel>
    </Box>
  );
}
