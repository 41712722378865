import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Email, Search } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
export const VerifyBusiness = ({ open, setOpen }) => {
  const [text, setText] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, remove, isSuccess, isError, data, error, refetch } =
    useQuery(
      ["search_email"],
      () => {
        return axios.get(
          `https://api.amaklo.com/amakloapi/_v1/businesses/temp/search?email=${email}`
        );
      },
      {
        enabled: Boolean(email),
      }
    );
  const {
    control,
    handleSubmit,
    reset: resetEmail,
    register,
    formState: { errors },
  } = useForm();

  const {
    mutate: verifyBusiness,
    isLoading: registerLoading,
    isSuccess: submitsucess,
    reset,
  } = useMutation(
    (business_ref, payment_ref) => {
      return axios.post(
        "https://api.amaklo.com/amakloapi/_v1/businesses/temp/submitpayment",
        business_ref,
        payment_ref
      );
    },
    {
      onSuccess: () => {
        refetch();
        setOpen(false);
        enqueueSnackbar("Payment info submited successfully ", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );
  React.useEffect(() => {
    if (!open) {
      reset();
      resetEmail({
        business_email: "",
      });
      setText(null);
      setEmail(null);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="paper"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          boxShadow: "0 2px 3px rgb(0 0 0 / 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Verify your business
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "300px",
          color: "primary.main",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.2rem",
            fontStyle: "italic",
            fontWeight: "bold",
          }}
        >
          Enter business Email address to verify
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            placeholder="Business email"
            required
            //   fullWidth
            id="business_email"
            name="business_email"
            {...register("business_email", { required: true })}
            autoFocus
            variant="outlined"
            disabled={data}
            onChange={(e) => setText(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {(!data && (
                    <IconButton onClick={() => setEmail(text)}>
                      <Search />
                    </IconButton>
                  )) ||
                    (isLoading && <CircularProgress size={20} />)}
                </InputAdornment>
              ),
            }}
            sx={{
              width: "50%",
              mt: 3,
            }}
          />
          {!isError && data && (
            <Box
              onSubmit={handleSubmit}
              sx={{
                width: "50%",
              }}
            >
              <Controller
                name="payment_ref"
                control={control}
                rules={{
                  required: "Payment ref is required",
                }}
                render={({ field }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    size="small"
                    placeholder="Payment ref"
                    helperText={errors?.name ? errors?.name?.message : ""}
                    error={errors?.name}
                    {...field}
                    sx={{
                      mt: 3,
                      py: 1,
                    }}
                  />
                )}
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "#FAFAFA",
          py: 3,
          px: 4,
          display: "flex",
          justifyContent: "flex-end",
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
      >
        <Button
          color="inherit"
          onClick={() => {
            reset();
            setOpen(false);
            setEmail(null);
          }}
          variant="contained"
          sx={{ width: 120, fontWeight: "bold" }}
        >
          Cancel
        </Button>
        <Box sx={{ px: 1 }} />
        <Button
          color="primary"
          variant="contained"
          disabled={!data?.data?._id || isLoading}
          sx={{ width: 120, fontWeight: "bold", color: "#fff" }}
          onClick={handleSubmit((e) => {
            verifyBusiness({
              payment_ref: e.payment_ref,
              business_ref: data?.data?._id,
            });
          })}
        >
          Verify
        </Button>
      </DialogActions>
    </Dialog>
  );
};
