/* eslint-disable array-callback-return */
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
} from "@mui/material";
import { LastPage } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Abyssinia, Awash, Cbe } from "../../../assets";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const steps = ["Price", "Business info", "Owner info"];

export default function BusinessRegisteration() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const [values, setValues] = React.useState({
    // price: undefined,
    business_name: undefined,
    business_email: undefined,
    business_address: undefined,
    package_ref: undefined,
    category_ref: undefined,
    owner_name: undefined,
    owner_email: undefined,
    owner_phonenumber: undefined,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.log(event.target.value);
  };
  console.log(values);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValues(undefined);
    handleReset();
  };
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({});
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: registerBusiness, isLoading: registerLoading } = useMutation(
    ({ data }) => {
      console.log(data);
      return axios.post(
        "https://api.amaklo.com/amakloapi/_v1/businesses/temp/register",
        data
      );
    },
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar("Businees  added successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery(
    ["package"],
    () => {
      return axios.get(
        `https://api.amaklo.com/amakloapi/_v1/package/paginate`
      );
    },
    {
      refetchOnWindowFocus: true,
      enabled: true,
    }
  );
  const { isLoading: loadingCategory, data: category } = useQuery(
    ["category"],
    () => {
      return axios.get(
        `https://api.amaklo.com/amakloapi/_v1/categories/all`
      );
    },
    {
      refetchOnWindowFocus: true,
      enabled: true,
    }
  );
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    // handleNext();
    registerBusiness({
      data: values,
    });
    handleClickOpen();
  };
  return (
    <Box
      sx={{
        width: "100%",
        // height: "500px",
      }}
    >
      <Stepper
        nonLinear
        activeStep={activeStep}
        sx={{
          my: 3,
        }}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" fontWeight="bolder">
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Container
        sx={{
          py: 4,
        }}
      >
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <Box components={"form"}>
            {activeStep === 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    // border: "1px solid red",
                    alignItems: "center",
                    flexDirection: {
                      md: "column",
                      sm: "column",
                      xs: "column",
                    },
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                    }}
                  >
                    <Typography
                      sx={{ mt: 2, mb: 1, py: 1, fontWeight: "bold" }}
                    >
                      Choose plan
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{
                        mx: 1,
                        width: "90%",
                        justifyContent: "center",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Package
                      </InputLabel>
                      <Select
                        {...register("price", { required: true })}
                        value={values?.package_ref}
                        id="demo-simple-select"
                        onChange={handleChange("package_ref")}
                        disabled={!data}
                      >
                        {data?.data?.docs?.map((item, index) => (
                          <MenuItem value={item?.id} key={index}>
                            {item?.packageTitle}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                    }}
                  >
                    <Typography
                      sx={{ mt: 2, mb: 1, py: 1, fontWeight: "bold" }}
                    >
                      Choose Category
                    </Typography>
                    <FormControl
                      fullWidth
                      sx={{
                        width: "90%",
                        justifyContent: "center",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        value={values?.category_ref}
                        id="demo-simple-select"
                        onChange={handleChange("category_ref")}
                        disabled={!data}
                        label="Choose Category"
                      >
                        {category?.data?.map((item, index) => (
                          <MenuItem value={item?._id} key={index}>
                            {item?.category_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    mt: 4,
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button
                    disabled={
                      values?.package_ref === undefined ||
                      values?.category_ref === undefined
                    }
                    onClick={handleNext}
                    sx={{ mr: 1, fontWeight: "900" }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            )}
            {activeStep === 1 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 5,
                    alignItems: "center",
                  }}
                  components="form"
                  handleSubmit={(data) => {
                    console.log(data);
                  }}
                >
                  <TextField
                    required
                    // fullWidth
                    autoFocus
                    id="email"
                    placeholder="Enter Business name"
                    name="business_name"
                    value={values?.business_name}
                    autoComplete="business_name"
                    variant="outlined"
                    onChange={handleChange("business_name")}
                    // {...register("business_name", {
                    //   required: true,
                    //   min: 5,
                    // })}
                    sx={{
                      my: 2,
                      width: "100%",
                    }}
                  />
                  <TextField
                    // fullWidth
                    id="email"
                    value={values?.business_email}
                    placeholder="Enter Business Email Address"
                    name="business_email"
                    autoComplete="business_email"
                    variant="outlined"
                    onChange={handleChange("business_email")}
                    // {...register("business_email", {
                    //   required: true,
                    //   min: 5,
                    // })}
                    sx={{
                      my: 2,
                      width: "100%",
                    }}
                  />
                  <TextField
                    // fullWidth
                    id="email"
                    placeholder="Enter Business Address"
                    name="business_address"
                    value={values.business_address}
                    autoComplete="business_address"
                    variant="outlined"
                    onChange={handleChange("business_address")}
                    // {...register("business_address", {
                    //   required: true,
                    //   min: 5,
                    // })}
                    sx={{
                      my: 2,
                      width: "100%",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    disabled={
                      values.business_address === undefined ||
                      values.business_email === undefined ||
                      values.business_name === undefined
                    }
                    onClick={handleNext}
                    sx={{ mr: 1 }}
                    type="submit"
                  >
                    Next
                  </Button>
                </Box>
              </>
            )}
            {activeStep === 2 && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 5,
                    alignItems: "center",
                  }}
                >
                  <TextField
                    required
                    // fullWidth
                    autoFocus
                    id="email"
                    placeholder="Enter Owner name"
                    name="owner_name"
                    autoComplete="owner_name"
                    value={values.owner_name}
                    variant="outlined"
                    onChange={handleChange("owner_name")}
                    sx={{
                      my: 2,
                      width: "100%",
                    }}
                  />
                  <TextField
                    required
                    // fullWidth
                    id="email"
                    placeholder="Enter Owner Email Address"
                    name="owner_email"
                    autoComplete="owner_email"
                    value={values.owner_email}
                    variant="outlined"
                    onChange={handleChange("owner_email")}
                    sx={{
                      my: 2,
                      width: "100%",
                    }}
                  />
                  <TextField
                    required
                    // fullWidth
                    id="email"
                    placeholder="Enter Owner Phone number"
                    name="owner_phonenumber"
                    value={values.owner_phonenumber}
                    autoComplete="owner_phonenumber"
                    variant="outlined"
                    onChange={handleChange("owner_phonenumber")}
                    sx={{
                      my: 2,
                      width: "100%",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {!isLastStep && (
                    <Button type="submit" onClick={handleNext} sx={{ mr: 1 }}>
                      Next
                    </Button>
                  )}

                  <Button
                    disabled={
                      values.owner_name === undefined ||
                      values.owner_email === undefined ||
                      values.owner_phonenumber === undefined
                    }
                    onClick={handleComplete}
                    sx={{
                      fontWeight: 900,
                      fontSize: "18px",
                    }}
                  >
                    {completedSteps() === totalSteps() ? "Finish" : " Register"}
                  </Button>
                  {/* ))} */}
                </Box>
              </>
            )}
          </Box>
        )}
      </Container>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            color: "primary.main",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 900,
              textAlign: "center",
            }}
          >
            Pay with one of the following bank account
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              display: "flex",
              flexDirection: "column",
              my: 2,
            }}
          >
            <Box
              sx={{
                alignSelf: "center",
                border: 2,
                borderColor: "primary.main",
                borderRadius: 3,
                p: 3,
                mx: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Cbe}
                  alt="CBE"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                />

                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 900,
                  }}
                >
                  <span
                    style={{
                      color: "green",
                      marginRight: 13,
                    }}
                  >
                    CBE
                  </span>
                  - 1000514413456
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Abyssinia}
                  alt="CBE"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 900,
                    ml: 1,
                  }}
                >
                  <span
                    style={{
                      color: "green",
                      marginRight: 13,
                    }}
                  >
                    Abyssinia
                  </span>
                  - 118379284
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={Awash}
                  alt="Awash"
                  style={{
                    width: "60px",
                    height: "50px",
                    borderRadius: "50%",
                  }}
                />
                <Typography
                  sx={{
                    ml: 1,
                    fontSize: "18px",
                    fontWeight: 900,
                  }}
                >
                  <span
                    style={{
                      color: "green",
                      marginRight: 13,
                    }}
                  >
                    Awash
                  </span>
                  - 013041001680200
                </Typography>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Understood</Button>
          {/* <Button onClick={handleClose}>Agree</Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
}
