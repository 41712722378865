import { Email, Lock, Message } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { A4, contactUs } from "../../assets";
import { Trans, useTranslation } from "react-i18next";
const GetInTouch = () => {
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({});

  const { enqueueSnackbar } = useSnackbar();
  const { mutate, isLoading, isSuccess } = useMutation(
    (sender_email, sender_name, message) => {
      return axios.post(
        "https://api.amaklo.com/amakloapi/_v1/notify/email/contactus",
        sender_email,
        sender_name,
        message
      );
    },
    {
      onSuccess: () => {
        reset({
          email: "",
          name: "",
          message: "",
        });
        enqueueSnackbar("Your message is sent  successfully ", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
      onError: () => {
        enqueueSnackbar("Error happened try again ", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );
  const onSubmit = (data) => {
    mutate({
      sender_email: data.email,
      sender_name: data.name,
      message: data.message,
    });
  };
  const { t, i18n } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12} sm={5} md={4} lg={4}>
        <Box
          component={"img"}
          sx={{
            display: "flex",
            backgroundPosition: "center",
            backgroundRepeat: "inherit",
            backgroundSize: "cover",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "30%",
            my: 15,
            mx: 2,
          }}
          src={contactUs}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        md={8}
        lg={8}
        display="flex"
        alignItems="center"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          noValidate
          component={"form"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            color="primary.main"
            sx={{
              fontWeight: "bolder",
              fontSize: "35px",
            }}
          >
            {t("contactusfooter")}
          </Typography>
          <Controller
            name="name"
            control={control}
            // rules={{
            //   required: "name is required",
            // }}
            render={({ field }) => (
              <TextField
                fullWidth
                id="name"
                name="name"
                placeholder="Name"
                autoComplete="name"
                autoFocus
                variant="standard"
                // helperText={errors?.name ? errors?.name?.message : ""}
                // error={errors?.name}
                {...field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                sx={{
                  backgroundColor: "#00000026",
                  borderRadius: 2,
                  my: 2,
                  p: 3,
                  fontSize: "19px",
                  width: {
                    sm: "100%",
                    xs: "100%",
                    md: "50%",
                    lg: "50%",
                  },
                }}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            rules={{
              required: "email is required",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

                message: "Invalid email address",
              },
            }}
            render={({ field }) => (
              <TextField
                fullWidth
                id="email"
                name="email"
                placeholder="Email Address"
                autoComplete="email"
                autoFocus
                variant="standard"
                helperText={errors?.email ? errors?.email?.message : ""}
                error={errors?.email}
                {...field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                sx={{
                  backgroundColor: "#00000026",
                  borderRadius: 2,
                  my: 2,
                  p: 3,
                  fontSize: "19px",
                  width: {
                    sm: "100%",
                    xs: "100%",
                    md: "50%",
                    lg: "50%",
                  },
                }}
              />
            )}
          />
          <Controller
            name="message"
            control={control}
            rules={{
              required: "message is required",
            }}
            render={({ field }) => (
              <TextField
                fullWidth
                name="message"
                placeholder="Message"
                autoComplete="message"
                autoFocus
                multiline
                variant="standard"
                helperText={errors?.message ? errors?.message?.message : ""}
                error={errors?.message}
                {...field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Message />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                sx={{
                  backgroundColor: "#00000026",
                  borderRadius: 2,
                  my: 2,
                  mx: 1,
                  // p: 7,
                  p: 3,
                  fontSize: "19px",
                  width: {
                    sm: "100%",
                    xs: "100%",
                    md: "50%",
                    lg: "50%",
                  },
                }}
              />
            )}
          />
          <Box>
            <Button
              type="submit"
              sx={{
                bgcolor: "primary.main",
                color: "white",
                px: 4,
                my: 3,
                py: 2,
                borderRadius: 6,
                "&:hover": {
                  bgcolor: "primary.main",
                },
              }}
              disabled={isLoading}
            >
              {t("submitmess")}
            </Button>
          </Box>
          <br />
          <br />
          <br />
          <br />
        </Box>
      </Grid>
    </Grid>
  );
};

export default GetInTouch;
