import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Avatar,
  Box,
  IconButton,
  LinearProgress,
  Portal,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";

import BusinessDetailTab from "./tabs";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { A1, A2, A3 } from "../../../assets";

import { Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "./style.css";
import {
  Favorite,
  FavoriteBorderOutlined,
  FavoriteSharp,
  ThumbUpOffAltRounded,
} from "@mui/icons-material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useCount } from "../../../hooks/api";

export default function DetailModal({ open, setOpen, id }) {
  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };
  // var id = props.match.params.id;
  const { mutate: count } = useCount();

  const fetchSingleBusiness = async (businessId) => {
    return await axios.get(
      `https://api.amaklo.com/amakloapi/_v1/businesses/openfetchsingle/${businessId}`
    );
  };

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery(
    ["singlebusiness_detail", id],
    () => fetchSingleBusiness(id),
    {
      refetchOnWindowFocus: true,
      enabled: true,
      retry: true,
    }
  );

  // {
  //   data?.data?.docs?.map((bus, index) => <DetailModal id={bus?._id} />);
  // }

  React.useEffect(() => {
    refetch();
  }, [open, id, refetch]);

  const img = data?.data?.image_gallery || [(A1, A2, A3)];
  const { enqueueSnackbar } = useSnackbar();
  const {
    mutate: makeFavorite,
    isLoading: favoriteLaoding,
    isSuccess: favored,
  } = useMutation(
    (action) => {
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/businesses/react/${id}`,
        action,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },

    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar(" Added to favorite businesses", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
      onError: () => {
        enqueueSnackbar("Your review is not sent ", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );
  React.useEffect(() => {
    if (favored) {
      refetch();
    }
  }, [favored, refetch]);
  React.useEffect(() => {
    id &&
      count({
        _clicked: "business",
        _clickedtype: "business details",
        _ref: id,
      });
  }, [id]);

  //get all ratings
  let allFavorite = data?.data?.favorites;
  // find rating of current loged in user
  let userFavorite = data?.data?.favorites?.find(
    (f) => f?.user_id === window.localStorage.getItem("userId")
  );

  return (
    <Portal>
      <SnackbarProvider>
        <Dialog
          maxWidth="md"
          fullWidth
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              borderRadius: "20px",
            },
          }}
        >
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box color={theme.palette.primary.main}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {isLoading && <LinearProgress width="100%" />}
                  <Avatar
                    alt="Amaklo"
                    src={data?.data?.business_logo}
                    sx={{ mx: 1 }}
                  />
                  <Typography
                    sx={{
                      fontWeight: 800,
                      my: 1,
                      fontSize: "25px",
                    }}
                  >
                    {data?.data?.business_name}
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    ml: 4,
                  }}
                >
                  {data?.data?.address} {"  "} {data && " | "}
                  <span
                    style={{
                      ml: 2,
                      color: "green",
                      fontSize: "15px",
                    }}
                  >
                    {data?.data?.category_ref?.category_name}
                  </span>
                </Typography>
              </Box>

              <Box>
                <IconButton
                  disabled={!window.localStorage.getItem("user")}
                  sx={{
                    p: 2,
                    color: "primary.main",
                  }}
                  onClick={() => {
                    makeFavorite({
                      action: "favorite",
                    });
                  }}
                >
                  {favoriteLaoding ? (
                    <CircularProgress size={20} />
                  ) : (
                    <>
                      {allFavorite?.find(
                        (f) =>
                          f.user_id === window.localStorage.getItem("userId")
                      ) ? (
                        <Favorite fontSize="large" />
                      ) : (
                        <FavoriteBorderOutlined fontSize="large" />
                      )}
                    </>
                  )}
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Swiper
              slidesPerView={1}
              effect="flip"
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
              modules={[Autoplay, Pagination]}
            >
              {img.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <Box
                      sx={{
                        height: "400px",
                        width: "100%",
                        flexGrow: 2,
                        backgroundColor: "#E0E7FF",
                        backgroundImage: `url(${item})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <BusinessDetailTab data={data?.data} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </SnackbarProvider>
    </Portal>
  );
}
