import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const count = ({ _clicked, _ref, _clickedtype }) => {
  return axios.post(
    "https://api.amaklo.com/amakloapi/_v1/ownamaklo/clickable",
    { _clicked, _ref, _clickedtype }
  );
};

export const useCount = () => {
  return useMutation(count, {
    onSuccess: (data) => {
      console.log("result from mutaion", data);
    },
  });
};
