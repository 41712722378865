import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import PuffLoader from "react-spinners/PuffLoader";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CssBaseline />
      <Suspense fallback={<div className="w-screen h-screen flex items-center justify-center "> <PuffLoader color="#046d76" /></div>}>
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
