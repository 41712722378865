import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ConfirmationNumber, EmailOutlined } from "@mui/icons-material";

const steps = ["Get reset code", "Set new password"];

export default function ForgetPassword() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    email: undefined,
    code: undefined,
    password: undefined,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    console.log(event.target.value);
  };
  React.useEffect(() => {
    console.log(values);
  }, [values]);

  const {
    mutate: sendResetCode,
    isLoading: sendingResetCode,
    isSuccess: senResetCode,
  } = useMutation(
    ({ username }) => {
      return axios.post(
        `https://api.amaklo.com/amakloapi/_v1/auth/sendpwdresetcode`,
        { username }
      );
    },

    {
      onSuccess: () => {
        handleNext();
        enqueueSnackbar("check your email for reset code ", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
      onError: () => {
        enqueueSnackbar("Error happened please use correct email  ", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );
  const {
    mutate: resetPassword,
    isLoading: resetingPassword,
    error,
    isSuccess,
  } = useMutation(
    ({ username, reset_code, new_password }) => {
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/auth/resetuserpwd`,
        { username, reset_code, new_password }
      );
    },

    {
      onSuccess: () => {
        handleNext();
        enqueueSnackbar(
          `successfully reset password , login with your new password `,
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      },
      onError: () => {
        enqueueSnackbar(`${error.response.data?.message}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  return (
    <Box
      sx={{
        width: "50%",
        mx: "auto",
        my: 15,
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pt: 2,
              alignItems: "center",
            }}
          >
            <Typography sx={{ mt: 2, my: 3, color: "primary.main" }}>
              Password rested successfully go to login page
            </Typography>
            <Button
              sx={{
                color: "white",
                mx: 2,
                px: 4,
                py: 2,
                borderRadius: 5,
                bgcolor: "primary.main",
                "&:hover": {
                  bgcolor: "primary.main",
                  color: "white",
                },
              }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {activeStep === 0 && (
            <Box
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                mt: 8,
              }}
            >
              <TextField
                onChange={handleChange("email")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                fullWidth
                placeholder="email"
                autoComplete="username"
                autoFocus
                sx={{
                  py: 2,
                }}
              />
              <Button
                sx={{
                  color: "white",
                  mx: 2,
                  px: 4,
                  py: 2,
                  borderRadius: 5,
                  fontWeight: 900,
                  textTransform: "capitalize",
                  bgcolor: "primary.main",
                  "&:hover": {
                    bgcolor: "primary.main",
                    color: "white",
                  },
                }}
                disabled={sendingResetCode}
                onClick={() => {
                  sendResetCode({ username: values?.email });
                }}
              >
                Reset code
              </Button>
            </Box>
          )}

          {activeStep === 1 && (
            <Box
              sx={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                mt: 8,
              }}
            >
              <TextField
                // onChange={handleChange("email")}
                disabled
                defaultValue={values?.email}
                value={values?.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                fullWidth
                placeholder="email"
                autoComplete="username"
                autoFocus
                sx={{
                  py: 2,
                }}
              />
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ConfirmationNumber />
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange("code")}
                margin="normal"
                fullWidth
                placeholder="Reset code"
                autoFocus
                sx={{
                  py: 2,
                }}
              />
              <TextField
                onChange={handleChange("password")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                fullWidth
                placeholder="Password"
                autoFocus
                sx={{
                  py: 2,
                }}
              />
              <Button
                sx={{
                  color: "white",
                  mx: 2,
                  px: 4,
                  py: 2,
                  borderRadius: 5,
                  fontWeight: 900,
                  textTransform: "capitalize",
                  bgcolor: "primary.main",
                  "&:hover": {
                    bgcolor: "primary.main",
                    color: "white",
                  },
                }}
                disabled={resetingPassword}
                onClick={() => {
                  resetPassword({
                    username: values?.email,
                    reset_code: values?.code,
                    new_password: values?.password,
                  });
                }}
              >
                Enter Reset Code
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
