import React from "react";
import { SvgIcon } from "@mui/material";

const Tiktok = () => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="26.25"
      height="26.251"
      viewBox="0 0 26.25 26.251"
    >
      <g
        id="Group_59936"
        data-name="Group 59936"
        transform="translate(-279.004)"
      >
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M13.125,26.251A13.125,13.125,0,0,1,3.844,3.845,13.125,13.125,0,0,1,22.405,22.407,13.036,13.036,0,0,1,13.125,26.251ZM11.359,11.306a4.537,4.537,0,0,0-2.929,1.1,4.482,4.482,0,0,0-1.546,2.971c-.014.317-.006.629,0,.893a4.52,4.52,0,0,0,2.188,3.422,4.4,4.4,0,0,0,2.225.623c.064,0,.139,0,.22-.006a4.459,4.459,0,0,0,3.542-1.924,4.593,4.593,0,0,0,.808-2.361c.011-1.084.011-2.188.01-3.255,0-.638,0-1.32,0-1.987a7.579,7.579,0,0,0,.97.557,6.358,6.358,0,0,0,2.517.582V9.507a4.29,4.29,0,0,1-2.54-1.073,3.7,3.7,0,0,1-1.049-2.5c-.308,0-.621,0-.924,0h-.005c-.463,0-.94,0-1.413.009-.012,1.557-.011,3.141-.01,4.539v.332c0,.774,0,1.575,0,2.362,0,.279,0,.561,0,.834,0,.778,0,1.582-.042,2.377a1.3,1.3,0,0,1-.229.606l-.017.029a2.014,2.014,0,0,1-1.659.965c-.049,0-.1.007-.151.007a2.054,2.054,0,0,1-1.947-1.727c0-.037,0-.075,0-.112v-.038a1.942,1.942,0,0,1,.088-.814,2.065,2.065,0,0,1,.815-1.049,1.95,1.95,0,0,1,1.117-.331,2.253,2.253,0,0,1,.692.108c0-.394.007-.806.013-1.138.009-.472.018-1.006.011-1.522A4.082,4.082,0,0,0,11.359,11.306Z"
          transform="translate(279.004)"
        />
      </g>
    </SvgIcon>
  );
};

export default Tiktok;
