import { Box } from "@mui/material";
import React from "react";
import Advert from "../../components/home/Advert";
import BusinessCard from "../../components/home/BusinessCard";
import Categories from "../../components/home/Categories";
import DetailModal from "../../components/home/Detail";

export const CategoryContext = React.createContext();

function Home() {
  const [open, setOpen] = React.useState(false);
  const [queryText, setSearchText] = React.useState("");
  const [id, setId] = React.useState();
  const [categoryRef, setCategoryRef] = React.useState("");
  window.localStorage.setItem("category", categoryRef);
  return (
    <CategoryContext.Provider value={{ categoryRef, setCategoryRef }}>
      <Box>
        <Advert
          text={queryText}
          searchText={setSearchText}
          title="Business Name"
        />
        <Categories />
        <BusinessCard
          category_ref={categoryRef}
          search={queryText}
          setId={setId}
          openModal={setOpen}
        />
        <DetailModal id={id} open={open} setOpen={setOpen} />
      </Box>
    </CategoryContext.Provider>
  );
}

export default Home;
