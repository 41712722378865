import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
} from "@mui/material";
import React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BusinessImage from "../../../assets/images/bussiness1.jpeg";
import { Box } from "@mui/system";
import { grey } from "@mui/material/colors";
import ClassIcon from "@mui/icons-material/Class";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { VerifyBusiness } from "../VerifyBusiness";
import { ExpandMoreRounded, StarRate, Visibility } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
function BusinessItem({
  business_name,
  address,
  image,
  category_name,
  openModal,
  id,
  setId,
  business_views,
  rate,
}) {
  const clickHandler = () => {
    setId(id);
    openModal(true);
  };
  console.log(">?===>?>?>", id);
  const { t, i18n } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      sx={{
        my: 1,
      }}
    >
      <Card sx={{ maxWidth: 355 }}>
        <CardMedia
          component="img"
          height="270"
          image={image}
          alt="green iguana"
          sx={{
            borderRadius: "10px 10px 0 0",
          }}
        />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Typography
              gutterBottom
              sx={{
                fontWeight: 700,
              }}
            >
              {business_name}
            </Typography>

            <Button
              variant="contained"
              sx={{ px: 3, borderRadius: "1px", height: "fit-content" }}
              size="small"
              onClick={clickHandler}
            >
              {t("detail")}
            </Button>
          </Box>
          <Typography variant="body2" color="text.secondary" textAlign="left">
            {address}
          </Typography>
          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Chip
              size="small"
              icon={<ClassIcon />}
              label={category_name}
              sx={{ mx: 3 }}
            />
            <Box sx={{ display: "flex" }}>
              <StarRate
                sx={{
                  color: "primary.main",
                  mx: 1,
                }}
              />
              <Typography
                sx={{
                  fontSize: "15px",
                }}
              >
                {rate}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Visibility sx={{ mx: 1, color: "primary.main" }} />
              <Typography
                sx={{
                  fontSize: "15px",
                }}
              >
                {business_views}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

function BusinessCard({ search, setId, openModal, category_ref }) {
  const [open, setOpen] = React.useState(false);
  const [perPage, setPerPage] = React.useState(6);
  // const { category_ref, setCategoryRef } = React.useContext(CategoryContext);

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["business_lists"],
    () => {
      return axios.get(
        `https://api.amaklo.com/amakloapi/_v1/businesses/openpaginate?per_page=${perPage}`,
        {
          params: {
            search_key: search,
            category_ref: category_ref,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: true,
      enabled: true,
      retry: true,
    }
  );

  React.useEffect(() => {
    refetch();
  }, [search, perPage, category_ref]);

  console.log({ data });

  const { t, i18n } = useTranslation();

  return (
    <>
      {data && (
        <Box pt={5} pb={5}>
          {search && <Typography color={grey[600]}>Search results</Typography>}
          <Box
            pt={7}
            // mx={-2}
            sx={{ backgroundColor: "#F7F7F7", px: "7%" }}
            pb={5}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              mb={3}
              alignSelf={"flex-end"}
            >
              {/* <Box>
                <Typography
                  sx={{
                    fontSize: "25px",
                  }}
                >
                  BUSINESSES
                </Typography>
                <Typography variant="subtitle1">
                  Browse Through And Select The Business. See Details On The
                  Business
                </Typography>
              </Box> */}
              <Button
                variant="contained"
                sx={{
                  px: 5,
                  py: 1,
                  borderRadius: "px",
                  alignItems: "flex-end",
                  justifySelf: "flex-end",
                }}
                size="small"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t("verify")}
              </Button>
            </Box>

            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {data?.data?.docs?.map((bus, index) => (
                <BusinessItem
                  category_name={bus?.category_ref?.category_name}
                  id={bus?._id}
                  openModal={openModal}
                  setId={setId}
                  image={bus?.cover_image || BusinessImage}
                  {...bus}
                  business_views={bus?.business_views}
                  rate={bus?.rates}
                />
              ))}
            </Grid>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mt: 4,
              }}
            >
              <Button
                sx={{ alignSelf: "center", bgcolor: "#E6F4F1" }}
                onClick={() => setPerPage(perPage + 3)}
                startIcon={
                  isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <ExpandMoreRounded
                      fontSize="large"
                      sx={{
                        color: "primary.main",
                      }}
                    />
                  )
                }
              >
                {t("morebusiness")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <VerifyBusiness open={open} setOpen={setOpen} />
    </>
  );
}

export default BusinessCard;
