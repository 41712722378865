import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export function useAuth() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    mutate,
    isLoading,
    isError,
    isSuccess,
    error,
    data: response,
  } = useMutation(
    (data) =>
      axios
        .post(
          "https://api.amaklo.com/amakloapi/_v1/auth/mobilelogin",

          data,
          {
            headers: {
              "Content-Type": "application/json",
              source: "web",
            },
          }
        )
        .then((res) => {
          const userToken = res.data?.userToken;
      
          window.localStorage.setItem("token", userToken);
          window.localStorage.setItem("realm", res.data?.realm);
          window.localStorage.setItem("userId", res.data?._id);
          window.localStorage.setItem("user", res.data?.fullName);
          window.localStorage.setItem("email", res.data?.email);
          window.localStorage.setItem("phone", res.data?.phoneNumber);
          window.localStorage.setItem("address", res.data?.address);
          window.localStorage.setItem("avatar", res.data?.avatar);
          window.localStorage.setItem("user-title", res.data?.userTitle);
          
         
         

        }),

    {
      onSuccess: () => {
        navigate("/");
      },
      onError: (error) => {
        enqueueSnackbar(`${error.response.data.message}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );

  return { mutate, isLoading, isError, isSuccess, error, data: response };
}
export function signOut() {
  window.localStorage.removeItem("token");
  window.localStorage.clear();
  window.location.reload();
}
