import {
  Lock,
  MailLock,
  Person,
  PhotoCamera,
  PlaceOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useSnackbar } from "notistack";
import MuiPhoneNumber from "material-ui-phone-number";

const Profile = () => {
  const [iState, setIState] = React.useState();
  const userTitle =  ["Mrs",'Miss','Ms',"Dr","Prof","Prof Dr","Rev Dr"]
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = React.useState({
    title: null,
    fullName: undefined,
    address: undefined,
    oldPassword: undefined,
    password: undefined,
    confirmPassword: undefined,

    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    if (prop === "phone" || prop === "profile")
      return setValues({ ...values, [prop]: event });
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    console.log(values);
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const {
    mutate: avatarUpdate,
    data,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(
    ({ avatar }) => {
      return axios.post(
        "https://api.amaklo.com/amakloapi/_v1/users/uploadavatar",
        avatar,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        window.localStorage.setItem("avatar", data?.data?.avatar);
        enqueueSnackbar("Profile updated successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },

      onError: () => {
        enqueueSnackbar("Profile Error", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );
  isSuccess && window.localStorage.setItem("avatar", data?.data?.avatar);

  const {
    mutate: updateUser,
    isLoading: updateLoading,
    isSuccess: userUpdatee,
    isError: errorUpdating,
  } = useMutation(
    ({ email, title, name, address }) => {
      
      return axios.put( `https://api.amaklo.com/amakloapi/_v1/users/update/${window.localStorage.getItem("userId")}`,
        {
          email,
          title,
          // password,
          // confirmPassword,
          name,
          address,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Profile information updated successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },

      onError: () => {
        enqueueSnackbar("updating profile details Error", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );

  console.log(data)

  const {
    mutate: updatePassword,
    isLoading: updatePasswordLoading,
    isSuccess: updatedPassword,
    isError: errorUpdatingPassword,
  } = useMutation(
    ({ username, current_password, updated_password, address }) => {
      return axios.post(
        `https://api.amaklo.com/amakloapi/_v1/auth/changepassword`,
        {
          username,
          updated_password,
          current_password,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Password updated successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },

      onError: () => {
        enqueueSnackbar(" Error updating password", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );

  const handleSelectImage = (event) => {
    setIState({
      currentFile: event.target.files[0],
      previewImage: URL.createObjectURL(event.target.files[0]),
    });
  };

  let formData = new FormData();
  formData.append("avatar", iState?.currentFile);

  let updateProfilePicture = () => {
    iState?.currentFile && avatarUpdate({ avatar: formData });
  };
  console.log(data?.avatar)

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        my: 2,
      }}
    >
      <Typography
        component="h1"
        variant="h5"
        sx={{
          color: "#046d76",
          fontWeight: 900,
          fontSize: "27px",
        }}
      >
        {" "}
        Update profile
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: " center ",
          my: 3,
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {!iState?.previewImage ? (
          <Avatar
            type="file"
            alt="Amaklo"
            src={window.localStorage.getItem("avatar")}
            sx={{
              bgcolor: "#E6E6E6",
              width: 80,
              height: 80,
            }}
          />
        ) : (
          <Avatar
            data-cy="Modal_Category_Image"
            type="file"
            id="Modal_Category_Image"
            src={iState?.previewImage}
            sx={{
              bgcolor: "#E6E6E6",
              width: 80,
              height: 80,
            }}
          />
        )}

        <Button variant="text" component="label">
          <Box
            component={"input"}
            id="category_logo"
            type="file"
            style={{
              opacity: 0,
              position: "absolute",
              top: 0,
              left: 0,
            }}
            onChange={handleSelectImage}
          />
          <PhotoCamera
            fontSize="large"
            sx={{
              color: "primary.main",
            }}
          />
        </Button>
        <Button
          variant="text"
          component="label"
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            textTransform: "capitalize",
            my: 1,
            borderRadius: 5,
            px: 2,
            "&:hover": {
              backgroundColor: "primary.main",
              color: "white",
            },
          }}
          onClick={() => {
            updateProfilePicture();
          }}
        >
          {" "}
          Update profile picture
        </Button>
      </Box>

      {/* <Box sx={{ minHeight: "inherit", my: 5 }}> */}
      <Grid
        container
        justifyContent="space-around"
        // alignItems="center"
        // sx={{ border: 2 }}
        rowGap={4}
        // columnGap={2}
      >
        <Grid
          component={Paper}
          elevation={12}
          item
          lg={4}
          md={10}
          sm={12}
          xs={12}
          sx={{
            m: 2,
            py: 2,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            // width: "100%",
          }}
        >
          {" "}
          <Grid item xs={8}>
            <Typography
              fontWeight={900}
              sx={{
                textAlign: "center",
                mt: 2,
              }}
            >
              Update your profile information
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ width: "80%", m: 3 }}>
            <Select
              required
              fullWidth
              id="title"
              name="title"
              autoComplete="title"
              autoFocus
              defaultValue={window.localStorage.getItem("user-title")}
              onChange={handleChange("title")}
            >
              <MenuItem value={window.localStorage.getItem("user-title")}>{window.localStorage.getItem("user-title")}</MenuItem>
              {
                userTitle.map((user,userkey)=>{
                  console.log(user +''+ window.localStorage.getItem("user-title"))
                    if(user === window.localStorage.getItem("user-title")){
                      return ''
                    }
                    else{
                          return <MenuItem value={user}>{user}</MenuItem>
                    }


                })
              }
           
        
     
            </Select>
          </Grid>
          <Grid item xs={12} sx={{ width: "80%", m: 3 }}>
            <TextField
              required
              fullWidth
              id="fullName"
              placeholder="Full Name"
              name="fullName"
              autoComplete="fullName"
              autoFocus
              defaultValue={window.localStorage.getItem("user")}
              variant="outlined"
              onChange={handleChange("fullName")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "80%", m: 3 }}>
            <TextField
              required
              fullWidth
              id="email"
              placeholder="Enter Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              variant="outlined"
              defaultValue={window.localStorage.getItem("email")}
              disabled
              onChange={handleChange("email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailLock />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "80%", m: 3 }}>
            <MuiPhoneNumber
              value={window.localStorage.getItem("phone")}
              disabled
              fullWidth
              defaultCountry={"et"}
              onChange={handleChange("phone")}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "80%", m: 3 }}>
            <TextField
              required
              fullWidth
              id="address"
              placeholder="Enter  Address"
              name="email"
              autoComplete="address"
              autoFocus
              variant="outlined"
              defaultValue={window.localStorage.getItem("address")}
              onChange={handleChange("address")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PlaceOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <Button
              type="submit"
              fullWidth
              onClick={(e) => {
                e.preventDefault();
                updateUser({
                  title: values.title,
                  name: values.fullName,
                  address: values.address,
                  // password: values.password,
                  // confirmPassword: values.confirmPassword,
                });
              }}
              variant="contained"
              sx={{
                mty: 3,
                textTransform: "capitalize",
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              Update information
            </Button>
          </Grid>
        </Grid>
        <Grid
          component={Paper}
          elevation={12}
          item
          lg={4}
          md={10}
          sm={12}
          xs={12}
          sx={{
            my: 3,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Grid item xs={8}>
            <Typography
              fontWeight={900}
              sx={{
                textAlign: "center",
                mt: 2,
              }}
            >
              Update your password
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mx: 3,
              width: "80%",
            }}
          >
            <TextField
              required
              fullWidth
              id="email"
              placeholder="Enter Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              variant="outlined"
              defaultValue={window.localStorage.getItem("email")}
              disabled
              onChange={handleChange("email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailLock />
                  </InputAdornment>
                ),
              }}
              sx={{ my: 3 }}
            />
            <TextField
              type={values.showPassword ? "text" : "password"}
              value={values.oldPassword}
              onChange={handleChange("oldPassword")}
              placeholder="Old Password"
              required
              fullWidth
              id="password"
              name="old_password"
              autoComplete="Old_password"
              autoFocus
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ my: 3 }}
            />
            <TextField
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              placeholder="New Password"
              required
              fullWidth
              id="password"
              name="password"
              autoComplete="password"
              autoFocus
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ my: 3 }}
            />
            <TextField
              type={values.showPassword ? "text" : "password"}
              value={values.confirmPassword}
              onChange={handleChange("confirmPassword")}
              placeholder="Confirm Password"
              required
              id="confirmPassword"
              name="confirmPassword"
              autoFocus
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ my: 3 }}
            />
          </Grid>
          <Grid item xs={12} sx={{ minWidth: "50%" }} alignSelf="center">
            <Button
              type="submit"
              fullWidth
              disabled={
                values.password !== values.confirmPassword || !values.password
              }
              onClick={(e) => {
                e.preventDefault();
                updatePassword({
                  username: window.localStorage.getItem("email"),
                  current_password: values.oldPassword,
                  updated_password: values.password,
                });
              }}
              variant="contained"
              sx={{
                mt: 3,
                textTransform: "capitalize",
                mb: 3,

                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
              }}
            >
              Update Password
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    // </Box>
  );
};

export default Profile;
