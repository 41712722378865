import React from "react";
import { Outlet } from "react-router-dom";
import BottomNav from "./Footer";

const Layout = ({ children }) => {
  return (
    <>
      {/* <Header /> */}
      <Outlet />
      {children}
      <BottomNav />
    </>
  );
};

export default Layout;
