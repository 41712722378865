import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { AboutUs1, AboutUs2, AboutUs3 } from "../../assets";

const AboutUs = () => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        my: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: "32px",
          fontWeight: "bold",
          color: "primary.main",
          my: 3,
        }}
      >
        About US
      </Typography>
      <Grid container>
        <Divider width="100%" />

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            ml: 12,
          }}
        >
          <Typography variant="h7" align="left">
            Amaklo is a business guide online digital platform with diverse full
            services provider and company Information, Location and Marketing
            Agency established to help and connect businesses, product services
            and consumers each other in a special easy way. We are here to give
            your business or service the digital platform it needs to help you
            connect with customers and explores from international and local
            market. The user companies register in the Amaklo plat form and get
            more than 20 different kinds of services and express themselves to
            introduce and advertise their products and services.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component={"img"}
            sx={{
              width: "300px",
              height: "300px",
            }}
            src={AboutUs1}
          />
        </Grid>
        <Divider width="100%" />
        <Grid
          xs={12}
          sm={12}
          md={6}
          lg={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component={"img"}
            sx={{
              width: "300px",
              height: "300px",
            }}
            src={AboutUs2}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            mr: 3,
          }}
        >
          <Typography variant="h7" sx={{ fontWeight: 500 }} align="left">
            We provide a digital platform for Hospitality Services provider,
            Travelers, and Business owners to have a digital platform to
            advertise and connect with new customers. we assist in enhancing
            your business grow much faster with our best-driven Amaklo services.
            We constantly advertise our platform to highlight your brands and
            services to our Ethiopian, Diaspora, and International Users.
          </Typography>
        </Grid>
        <Divider width="100%" />

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            ml: 12,
          }}
        >
          <Typography variant="h7" align="left">
            Amaklo can help you create a digital presence through our platform,
            get new customers, advertise your Company & services on Amaklo
            website, android & IOS platform to new users, make your reachability
            convenient & easy to local & International clients. We’ll help you
            grow your business, online audience, expand your reach and win more.
            It's Time to Go Digital, Build Your Online Strategy with Amaklo Join
            the online community because that is where the world is today.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            component={"img"}
            sx={{
              width: "300px",
              height: "300px",
            }}
            src={AboutUs3}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AboutUs;
