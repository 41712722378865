import { Box, CardMedia, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { Business, FavoriteRounded, Newspaper } from "@mui/icons-material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Blogimg from "../../assets/images/blogsmall.jpg";
import moment from "moment";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";

function FavoriteItem({
  type,
  date,
  name,
  avatar,
  makeFavorite,
  differentDate,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        ml: 6,
        my: 4,
        width: "70%",
      }}
    >
      {differentDate && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography>{date}</Typography>
          <Divider
            variant="middle"
            sx={{
              width: "40%",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "80%",
            my: 1,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: 5,
            }}
            src={avatar || Blogimg}
            alt="favorite"
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                ml: 4,
              }}
              fontWeight={900}
            >
              {name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                m: 0,
              }}
            >
              {type === "business" ? (
                <Business
                  sx={{
                    mx: 1,
                  }}
                />
              ) : (
                <Newspaper
                  sx={{
                    mx: 1,
                  }}
                />
              )}
              <Typography variant="body1" color="text.primary">
                {type}
              </Typography>
            </Box>
          </Box>
        </Box>
        <IconButton
          sx={{
            justifyContent: "end",
          }}
          onClick={makeFavorite}
        >
          <FavoriteRounded />
        </IconButton>
      </Box>
    </Box>
  );
}

const Favorite = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [blogId, setBlogId] = React.useState(null);
  const [businessId, setBusinessId] = React.useState(null);
  const [id, setId] = React.useState(null);

  const {
    mutate: makeFavorite,
    isLoading: favoriteLaoding,
    isSuccess: favored,
  } = useMutation(
    (action) => {
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/blogs/react/${blogId}`,
        action,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar(`updated favorite blog `, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
      onError: () => {
        enqueueSnackbar("error happened updating blog ", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );
  const {
    mutate: makeBusinessFavorite,
    isLoading,
    isSuccess,
  } = useMutation(
    (action) => {
      return axios.put(
        `https://api.amaklo.com/amakloapi/_v1/businesses/react/${businessId}`,
        action,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },

    {
      onSuccess: () => {
        refetch();
        enqueueSnackbar(" updated your favorite business", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
      onError: () => {
        enqueueSnackbar("error happened  updating business", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      },
    }
  );

  const { isError, data, error, refetch } = useQuery(
    ["singlebusiness_detail"],
    () => {
      return axios.get(
        `https://api.amaklo.com/amakloapi/_v1/users/fetchsingle/${window.localStorage.getItem(
          "userId"
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: true,
      enabled: true,
    }
  );

  React.useEffect(() => {
    if (favored) {
      refetch();
    }
  }, [favored, refetch]);

  let updateBusinessFavorites = (id) => {
    businessId(id);
    makeBusinessFavorite({
      action: "favorite",
    });
  };
  let updateBlogFavorites = (id) => {
    setBlogId(id);
    makeFavorite({
      action: "favorite",
    });
  };

  var sameDate = [];
  var differentDate = [];

  for (var i = 0; i < data?.data?.favorites?.length; i++) {
    if (data?.data?.favorites[i]._date === data?.data?.favorites[0]?._date) {
      sameDate.push(data?.data?.favorites[i]);
    } else {
      differentDate.push(data?.data?.favorites[i]);
    }
  }

  // console.log("same", sameDate);
  // console.log("diffrenet", differentDate);
  const { t, i18n } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          width: "70%",
        }}
      >
        <IconButton
          sx={{
            my: 2,
            color: "gray",
          }}
        >
          <FavoriteIcon fontSize="large" sx={{ mx: 1 }} />
        </IconButton>
        <Typography textAlign={"center"} color="#000" fontWeight={600}>
          {t("favorite")}
        </Typography>
      </Box>
      {sameDate?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            width: "70%",
            ml: 6,
          }}
        >
          <Typography>{sameDate[0]._date}</Typography>
          <Divider
            variant="middle"
            sx={{
              width: "40%",
            }}
          />
        </Box>
      )}
      {sameDate &&
        sameDate?.map((item, index) => {
          return (
            <FavoriteItem
              key={index}
              name={item?.title}
              date={item?._date}
              avatar={item?._image}
              type={item?.type}
              differentDate={false}
              makeFavorite={() => {
                if (item?.type === "business") {
                  updateBusinessFavorites(item?.id);
                } else {
                  updateBlogFavorites(item?.id);
                }
              }}
            />
          );
        })}
      {differentDate &&
        differentDate?.map((item, index) => {
          return (
            <FavoriteItem
              key={index}
              name={item?.title}
              date={item?._date}
              avatar={item?._image}
              type={item?.type}
              differentDate={true}
              makeFavorite={() => {
                if (item?.type === "business") {
                  updateBusinessFavorites(item?.id);
                } else {
                  updateBlogFavorites(item?.id);
                }
              }}
            />
          );
        })}
    </Box>
  );
};

export default Favorite;
